export const GoDeeperItems = {
    "ep0": {
        preambleHTML: "",
        slideshowsList: [
            {
                slideshowId: "ep0_A",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd0_01",
                        number: "1",
                        filename: "01_teachingPrinciplesList.webp",
                        altText: "list of teaching epiphanies",
                        blurb: "So, let's get started! ...",
                        descriptionHTML: `<p>So, let's get started! In the <span class="text-italic">About</span> section, I noted the 7 ideas that the <span class="text-italic">Play Seriously</span> series explores are drawn from a longer list of "teaching epiphanies" &mdash; things that I think I have learned about teaching. It's a list that I have added to year-on-year as part of my own teaching improvement process. Making the list helps me to crystalize and generalize ideas that I think are important in creating learning environments that inspire people to do their best.</p>
        
            <p>For what it's worth, here's additional idea sound-bites from that list &mdash;in no particular order. There's a fair bit behind each statement, so if any pique your interest, it would be fun to discuss them with you on our forum. Just ask.</p>
        
            <p>A main goal of <span class="text-italic">Play Seriously</span> is to bring the 7 chosen principles to life through real, in-class activites of students. Pretty much every class period in the 3 month term had activities that could be used to illustrate a given principle &mdash; so, working with Director Brian Cassin, I carefully picked a subset of class activities to follow. There were 12 filming days throughout the ~95 day 2023 term &mdash; what the budget could accomodate!</p>`
                    },
                    {
                        id: "gd0_02a",
                        number: "2a",
                        filename: "02a_KresgeFinalPrototypeLaunch.webp",
                        altText: "Kresge Auditorium during 2.009 Prototype Launch",
                        blurb: "Kresge Auditorium ...",
                        descriptionHTML: `<p>This is Kresge Auditorium on December 11, 2023 during the 2.009 final prototype launch.</p>`
                    },
                    {
                        id: "gd0_02b",
                        number: "2b",
                        filename: "02b_excitementInKresge.webp",
                        altText: "excitement in Kresge",
                        blurb: "An inspiring celebration ...",
                        descriptionHTML: `<p>An inspiring celebration for all, centered around what we do—design!</p>`
                    },
                    {
                        id: "gd0_03",
                        number: "3",
                        filename: "03_KresgeStudioSetup.webp",
                        altText: "Kresge studio setup",
                        blurb: "And this is Kresge ...",
                        descriptionHTML: `<p>And this is Kresge on March 21, 2024, converted to a studio for recording the explanatory narrative for the series. After recording the class in action during the fall of 2023, the narration was recorded in Kresge over 4 days in March. A busy 4 days.</p>`
                    },
                    {
                        id: "gd0_04",
                        number: "4",
                        filename: "04_cameras6k.webp",
                        altText: "cameras in Kresge",
                        blurb: "Wow, those are ...",
                        descriptionHTML: `<p>Wow, those are fancy cameras! For Play Seriously, our goal was to produce a high quality series with production that will stand the test of time. Everything—even the classroom footage—was recorded in high resolution 6K!</p>`
                    },
                    {
                        id: "gd0_05",
                        number: "5",
                        filename: "05_settingLightingUp.webp",
                        altText: "lighting setup in Kresge",
                        blurb: "Studio filming ...",
                        descriptionHTML: `<p>Studio filming involves a lot of test, sit around and wait, and test again—paying attention to and caring for the smallest of details. You'll hear more about that notion in Episode 1! Here, I'm feeling the thrill of posing while lighting adjustments are made.</p>`
                    },
                    {
                        id: "gd0_06",
                        number: "6",
                        filename: "06_scriptDiscussion.webp",
                        altText: "script discussion",
                        blurb: "And here we're ...",
                        descriptionHTML: `<p>And here we're noodling out a recording plan. Director Brian Cassin <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a> and I are thinking through the first part of the series introduction while props are being set up. The auditorium was heated, and Brian's a warm guy, so I'm not sure why he dressed like it's below freezing!</p>
        
            <p>I had written a script to follow, but, like in life, many good things often come in the moment—when you are open to new opportunities.</p>`
                    },
                    {
                        id: "gd0_07a",
                        number: "7a",
                        filename: "07a_introductionCrabStoryboard_p1.webp",
                        altText: "introducing the crab storyboard",
                        blurb: "Anyone who has been in ...",
                        descriptionHTML: `<p>Anyone who has been in a class I teach will have heard about storyboards—a tool for designing presentations, product user experiences, and yes...films! A visual script. Here's part of a storyboard Brian <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a> made to think about the crab mobile scene.</p>`
                    },
                    {
                        id: "gd0_7b",
                        number: "7b",
                        filename: "07b_introductionCrabStoryboard_p2.webp",
                        altText: "crab storyboard panel",
                        blurb: "The final entry ...",
                        descriptionHTML: `<p>The final entry onto the stage.</p>`
                    },
                    {
                        id: "gd0_08a",
                        number: "8a",
                        filename: "08a_crabInKillian.webp",
                        altText: "crab mobile in Killian Court",
                        blurb: "We filmed out-and-about ...",
                        descriptionHTML: `<p>We filmed out-and-about campus with the crab mobile for the introduction sequence—here at the symbol of MIT, Killian Court.  It was a drizzly, wet day, but that didn't dampen our ability to play seriously. We were on task, focused, and having fun.</p>`
                    },
                    {
                        id: "gd0_8b",
                        number: "8b",
                        filename: "08b_davidInKillian.webp",
                        altText: "Prof Wallace in Killian",
                        blurb: "Filming out-and-about ...",
                        descriptionHTML: `<p>Filming out-and-about also involves a lot of test, stand around and wait, and test again!</p>`
                    },
                    {
                        id: "gd0_09",
                        number: "9",
                        filename: "09_crabBlocking.webp",
                        altText: "crab mobile in Kresge",
                        blurb: "Back in Kresge ...",
                        descriptionHTML: `<p>Back in Kresge, we're figuring out blocking for driving the crab mobile onto the stage.  When you watched the series introduction, did you notice that the crab was pretty wrinkly? It's quite visible on stage.  And no, the crab wasn't molting—the printed paper graphic was wrinkled because it was wet from filming outdoors in the rain. Wet paper expands a lot, plywood (the crab's substrate) not-so-much. Voila, wrinkles are born. Arg, such is life.</p>`
                    },
                    {
                        id: "gd0_10",
                        number: "10",
                        filename: "10_crewInAction.webp",
                        altText: "filming crew in action",
                        blurb: "Director Brian Cassin and ...",
                        descriptionHTML: `<p>Director Brian Cassin <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a> and Director of Photography Matthew Dorris <a href="https://www.instagram.com/filouza" target="_blank">@filouza</a> and 1st Assistant Camera Asa Reed <a href="https://www.instagram.com/asareed" target="_blank">@asareed</a> filming the grand entrance!</p>
        
            <p>And for now, that's the introduction's backstory. Up next, <a href="/#ep1">Episode 1: Everything is an example</a>.</p>`
                    },
                ]
            }
        ]
    },
    "ep1": {
        preambleHTML: `<p>In episode 1, I used the 2.009 build challenge activity from fall 2023 to demonstrate the principle <span class="text-italic">everything is an example</span>, and to highlight how attending to every detail with care leads to something special &mdash; something that truly stands apart.  So, in this edition of <span class="text-italic">Back Stories</span>, I thought it would be interesting to dive into the build challenge itself.</p>

        <ol class="gd-abc-list">
            <li>What is the build challenge? Why do we do it? How do we do it?</li>
            <li>The 2023 build challenge. What was its story? What are the vehicle designs?</li>
            <li>Why does the build challenge change every year?</li>
        </ol>

<p>I'll also include videos and vehicle sketches for the build challenges that are discussed. And, if you have other thoughts or other questions, please join in our <a href="https://forum.playseriously.com" target="_blank">forum</a> and I'll respond as best that I can.</p>

<p>All right! Let's get started!</p>`,
        slideshowsList: [
            {
                slideshowId: "ep1_A",
                slideshowTitle: "A) What is the build challenge? Why do we do it? How do we do it?",
                slidesData: [
                    {
                        id: "gd1_A01",
                        number: "A1",
                        filename: "A01_theBuildChallenge.webp",
                        altText: "build challenge field",
                        blurb: "The build challenge! ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">What is the build challenge? Why do we do it? How do we do it?</p>
<p>The build challenge! As I said in episode 1, "It's extreme, it pushes limits, it's kind of crazy, it's euphoric. It's fun."</p>

<p>Fun indeed. Super important. But is that alone enough? My answer is... no! In teaching, I think it's important to think carefully about the lesson I'm trying to convey, and that every teaching action needs to somehow contribute to the mission of the learning experience. If something is included only because I think it's "cool", it's not much more than a "stunt" or "party trick", so to speak.</p> 

<p>I'm often asked why we don't promote the build challenge as a campus event. I don't because I want the students to know that it is for them and their learning experience alone. Nothing more.</p>`
                    },
                    {
                        id: "gd1_A02",
                        number: "A2",
                        filename: "A02_loveWhatWeDo.webp",
                        altText: "tired team",
                        blurb: "In the picture, we see ...",
                        descriptionHTML: `<p>In the picture, we see a tired, happy, and fulfilled Purple Team in the lab after a major prototyping milestone.</p> 

<p>I've said that my teaching goal is to build an audacious willingness to love what we do (which is product design), to learn how to work together, and to do-what-we-do well.</p>`
                    },
                    {
                        id: "gd1_A03",
                        number: "A3",
                        filename: "A03_ideateModelTest.webp",
                        altText: "ideate model test",
                        blurb: "The build challenge reflects ...",
                        descriptionHTML: `<p>The build challenge reflects this ideal; but, more specifically, I'm hoping to help students fully embrace the process of designing.</p>

                        <ul class="gd-no-bullet-list">
<li>Ideate &mdash; freely coming up with lots of ideas.</li>
<li>Model &mdash; building prototypes that represent ideas. <span class="text-italic">And</span></li> 
<li>Test! &mdash; using your models to experiment and learn.</li>
</ul>

<p>And then to start all over again, improving your ideas. Ideate. Model. Test!</p>`
                    },
                    {
                        id: "gd1_A04",
                        number: "A4",
                        filename: "A04_teamsAssembleVehicleInLab.webp",
                        altText: "team assembling vehicle",
                        blurb: "The build challenge is just ...",
                        descriptionHTML: `<p>The build challenge is just a low stakes practice run for this design cycle. Ideate. Model. Test! It runs in parallel to when student teams are executing their first design iteration for the real, "serious" products that they will conceive. This first iteration takes place over a three week cycle near the start of the term.</p>`
                    },
                    {
                        id: "gd1_A05",
                        number: "A5",
                        filename: "A05_vehicleConceptsInClass.webp",
                        altText: "students brainstorming in class",
                        blurb: "Day 1, Students Ideate! ...",
                        descriptionHTML: `<p class="text-italic">Day 1, Students Ideate!</p>

<p>Just as students are about to begin generating ideas for their products, we spend 30 minutes of class time practicing &mdash; individually ideating "silly vehicles" to complete a whimsical mission. That is, the build challenge's team relay-race mission!  The mission of the build challenge is usually an allegorical story connected to the year's "serious" product development theme. (I'll give you a specific example, in <span class="text-yellow">B4</span>, when I explain the story of the 2023 build challenge featured in episode 1.)</p>

<p>Then off the lab students go, working with their teams ideating for their real products! Meanwhile, behind the scenes, I typically work with former 2.009 student/teaching assistant/lecturer Victor Hung <a href="https://www.instagram.com/poofytoo" target="_blank">@poofytoo</a> sorting through all the student's ideas. Together we pick one interestingly ridiculous vehicle for each team. Then, I quickly flesh out each team's vehicle design, just enough to establish principles of propulsion. After discussing with the Pappalardo lab staff <a href="https://www.instagram.com/pappalardolab" target="_blank">@pappalardolab</a> and the class teaching assistants to get feedback, it's time to design the vehicles for real. I usually do this in a 24-30 hour marathon &mdash; sketching, sourcing parts, and working out details all at the same time.</p>`
                    },
                    {
                        id: "gd1_A06",
                        number: "A6",
                        filename: "A06_redTeamVehicleComponents.webp",
                        altText: "vehicle components",
                        blurb: "Day 10 ...",
                        descriptionHTML: `<p class="text-italic">Day 10</p>

<p>Pappalardo staff, teaching assistants, and a host of mentor volunteers spend a weekend together in the lab to make IKEA-style vehicle kits for each team. All together, about 30 kind folks help in the vehicle kit-building process. On this weekend, I also do the instruction sketches that the students will follow when they assemble their vehicles.</p>`
                    },
                    {
                        id: "gd1_A07",
                        number: "A7",
                        filename: "A07_purpleTeamBuildingVehicle.webp",
                        altText: "team building vehicle",
                        blurb: "Day 14, Model! ...",
                        descriptionHTML: `<p class="text-italic">Day 14, Model!</p>

<p>Just as student teams are about to start making exploratory models of their product ideas &mdash; very quick-and-dirty prototypes that we call <span class="text-italic">sketch models</span> &mdash; students spend a one hour class period in the lab building their vehicles from the kits that have been prepared for them. They get comfortable working in their team space, working with their tools, working with each other, and working on a timeline.</p>

<p>The vehicles provide examples of sketch-model-level prototyping; and, in making them, students are primed to work as a team on sketch models for their "serious" product ideas. Students are given this experience without distracting or taking time away from their primiary mission &mdash; to design a well-conceived product. It's just an hour of lecture time.</p>`
                    },
                    {
                        id: "gd1_A08a",
                        number: "A8a",
                        filename: "A08a_teamPresentsResearchAtReview.webp",
                        altText: "team presenting research",
                        blurb: "Day 18 ...",
                        descriptionHTML: `<p class="text-italic">Day 18</p>

<p>In their first major product development milestone, student teams present several competing product ideas under consideration. They explain their ideas and provide related market research.</p>`
                    },
                    {
                        id: "gd1_A08b",
                        number: "A8b",
                        filename: "A08b_teamDemonstratesSketchModelAtReview.webp",
                        altText: "team demonstrating sketch model",
                        blurb: "Day 18 (continued) ...",
                        descriptionHTML: `<p class="text-italic">Day 18 (continued)</p>
<p>The students also demonstrate ideas and key operational principles using simple sketch models. They discuss what they have learned in this initial ideate-model-test design cycle.</p>

<p>The goal is to explore many options at a high level to determine which product directions have the greatest potential.</p>`
                    },
                    {
                        id: "gd1_A09a",
                        number: "A9a",
                        filename: "A09a_buildChallengeDay.webp",
                        altText: "build challenge field",
                        blurb: "Day 19, Test! (AKA Build challenge ...",
                        descriptionHTML: `<p class="text-italic">Day 19, Test! (AKA Build challenge day!)</p>

<p>In the lecture period following the product milestone review, it's time to test their whimsical vehicles in the mission of the build challenge. Yes. the build challenge. Live in Killian Court. It's a fast, furious and fun team bonding experience.</p>`
                    },
                    {
                        id: "gd1_A09b",
                        number: "A9b",
                        filename: "A09b_buildChallengeDay.webp",
                        altText: "team bonding at build challenge",
                        blurb: "Day 19, Test! (continued) ...",
                        descriptionHTML: `<p class="text-italic">Day 19, Test! Build challenge day! (continued)</p>
<p>Who cares who wins? It's a cathartic release to cap off the completion of their first <span class="text-italic">Ideate. Model. Test!</span> cycle. And, ironically, this silly challenge and its level of execution clearly says to the students "This is different. Our expectation is to do things that are exceptional, and together we can realize the extra-ordinary".</p>`
                    },
                    {
                        id: "gd1_A10",
                        number: "A10",
                        filename: "A10_finalNightOfSetup.webp",
                        altText: "night before build challenge",
                        blurb: "The playing field! ...",
                        descriptionHTML: `<p>And, oh yes, the build challenge playing field! The course in Killian Court that the students navigate with their vehicles, racing to complete the build challenge's mission. So far, we've only talked about the students' vehicles. Here it is, in the final setup stages, in the wee-hours of the night before the challenge.</p>`
                    },
                    {
                        id: "gd1_A11",
                        number: "A11",
                        filename: "A11_playingFieldLayout.webp",
                        altText: "build challenge playing field",
                        blurb: "In August, typically, I ...",
                        descriptionHTML: `<p>In August, typically, I work with Victor Hung <a href="https://www.instagram.com/poofytoo" target="_blank">@poofytoo</a> and the course teaching assistants to define the challenge for the year, and to outline a concept for the playing field. While Victor and his team work on the major playing field set pieces, class TAs and I work on all the bits needed to make the event sing. Team T-shirts, custom hard hats, making individualized plushies for students, custom designed chocolate coins, goodies, instructions, costumes, event logistics, photography, spirit awards, the 2.009 build challenge cup, videos &mdash; the list goes on-and-on. Everything is designed to be an example.</p>`
                    },
                    {
                        id: "gd1_A12",
                        number: "A12",
                        filename: "A12_playingFieldSetupTimelapse.webp",
                        altText: "build challenge playing field setup",
                        videoInfo: {
                            playbackId: "u4xr2I2GDSJscKSoaZSaxAOKxesEYp7iygc9TUhoqLU",
                            title: "Build Challenge Behind the Scenes Timelapse",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "gd1_A12_build_challenge_timelapse",
                                video_title: "Build Challenge Behind the Scenes Timelapse",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "And how do all of these ...",
                        descriptionHTML: `<p>And how do all of these details get thought about and then addressed with great care? As my advisor Woodie Flowers said, "play the movie in your head". I painstakingly experience the entire challenge and its preceeding weeks in my head, as detailed and step-by-step as I can, carefully noting what needs to happen and when. Then one must build a team and delegate, working with individuals who treat every element to which they contribute, big or seemingly very small, with ownership, careful thought, and the utmost of importance. Folks that "play the movie in their head", too.</p>

<p>The challenge is set up in Killian Court over one day and night &mdash; so it is a surprise for students when they arrive. All together, well over 1500 person hours (not including the student's time) and the help of nearly 100 people is needed to make the challenge happen. Thank you helpers over the years!</p>

<p>The challenge is designed to provide a practice <span class="text-italic">ideate. model. test!</span> learning experience that is meaningful, playful, and does not compete with or distract from the students' "serious" product development mission. Instead, it actually augments and sharpens their focus.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_B",
                slideshowTitle: "B) The 2023 build challenge. What was its story? What are the vehicle designs?",
                slidesData: [
                    {
                        id: "gd1_B01",
                        number: "B1",
                        filename: "B01_wildAssociationMap.webp",
                        altText: "association map for Wild",
                        blurb: "Product development theme ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">The 2023 build challenge. What was its story? What are the vehicle designs?</p>
<p>The product development theme in the fall was <span class="text-italic">Wild!</span> Exciting, untamed, and incredible. Products that give us the feeling of "wow, that was wild!". This association map for <span class="text-italic">wild</span> was provided to students as a launching pad to think for themselves about what types of products the theme of <span class="text-italic">wild</span> might inspire.</p>`
                    },
                    {
                        id: "gd1_B02",
                        number: "B2",
                        filename: "B02_mildyGuidedByMissChiefs.webp",
                        altText: "Mildy guided by Miss Chiefs",
                        blurb: "How did the build challenge ...",
                        descriptionHTML: `<p>How did the build challenge relate to <span class="text-italic">wild</span>? It's a story of transformation, how the character "Mildy", oppressed by the Bureau of Boredom (BoB), is transformed through the efforts of critters called Miss Chiefs.</p>`
                    },
                    {
                        id: "gd1_B03",
                        number: "B3",
                        filename: "B03_WildyAndMissChiefs.webp",
                        altText: "Wildy and Miss Chiefs",
                        blurb: '"Mildy" transformed into "Wildy" ...',
                        descriptionHTML: `<p>"Mildy" transformed into "Wildy" through the power of the Miss Chiefs, in the wild!  "Wildy" &mdash; free to think and dream big!</p>

<p>I play Mildy and Wildy, and the students are the Miss Chiefs.</p>`
                    },
                    {
                        id: "gd1_B04",
                        number: "B4",
                        filename: "B04_2023BuildChallenge.webp",
                        altText: "2023 build challenge",
                        videoInfo: {
                            playbackId: "TZLo00OU65a01v02UHUNzYHi5PaEYebTU7SXM2adx024sGY",
                            title: "2023 Build Challenge - Into the Wild",
                            thumbnailTime: "5",
                            metadata: {
                                video_id: "gd1_B04_2023_build_challenge",
                                video_title: "2023 Build Challenge - Into the Wild",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "The build challenge video ...",
                        descriptionHTML: `<p>The build challenge video, prepared for showing at the 2023 2.009 final prototype launch event, will help you understand the flow of the challenge. At 1:15 in the video, there is a fun animation that explains the story of the build challenge mission. Enjoy!</p>`
                    },
                    {
                        id: "gd1_B05",
                        number: "B5",
                        filename: "B05_2023BuildVehicles.webp",
                        altText: "2023 build challenge vehicles",
                        blurb: "And, as promised, here are ...",
                        descriptionHTML: `<p>And, as promised, here are sketches of each team's vehicle for the 2023 build challenge. Download <a href="/back_stories/ep1/B05_2023BuildVehicles.pdf" target="_blank">PDF</a></p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_C",
                slideshowTitle: "C) The build challenge changes every year! But why?",
                slidesData: [
                    {
                        id: "gd1_C01",
                        number: "C1",
                        filename: "C01_2018dangerBuildChallenge.webp",
                        altText: "2018 build challenge",
                        blurb: "Changing allows the story ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">The build challenge changes every year! But why?</p>
                        <p>Changing allows the story of the challenge to reinforce each year's inspirational product development theme (which also changes every year). And this way students of each year know that it is <span class="text-italic">their</span> challenge. It is unique for them. This image is from 2018, when the year's design theme was <span class="text-italic">Danger</span>. The story of the build challenge pitted David Danger against Sir Safety, representing the fine balance of openly taking risks and also safe-guarding the well-being of those who we design for!</p>

<p>And, of course, by changing every year it lets us, the staff, try new things and improve, too! It's fun!</p>

<p>If the same challenge was dusted-off each year &mdash; which indeed is a lot less work &mdash; would we not be setting an example of being complacent, of standing still? Is that an example of excellence?</p>`
                    },
                    {
                        id: "gd1_C02",
                        number: "C2",
                        filename: "C02_2017buildChallenge.webp",
                        altText: "2017 build challenge",
                        videoInfo: {
                            playbackId: "27ygudoO9r1Qu7cBsrvkdpeizraUq4w6yxmLR00diiQA",
                            title: "2017 Build Challenge - Product Man",
                            thumbnailTime: "43",
                            metadata: {
                                video_id: "gd1_C02_2017_build_challenge",
                                video_title: "2017 Build Challenge - Product Man",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "A different build challenge ...",
                        descriptionHTML: `<p>Here's an example of a build challenge from a different year.</p>

<p>It's the fall of 2017. <span class="text-italic">Super</span> is the theme! It's the year of spontaneously combusting cell phones and the Juicero! Students use their super powers to rescue Superhero Product Man and defeat Dr. Defective, battling bad design!</p>`
                    },
                    {
                        id: "gd1_C03",
                        number: "C3",
                        filename: "C03_2017BuildVehicles.webp",
                        altText: "2017 build challenge vehicles",
                        blurb: "Sketches of 2017 build ...",
                        descriptionHTML: `<p>Here are sketches of 2017 build challenge vehicles. Download <a href="/back_stories/ep1/C03_2017BuildVehicles.pdf" target="_blank">PDF</a></p>

<p>If you'd like to see more, you can find many years' build challenge videos in the <a href="http://designed.mit.edu/new/index.html" target="_blank">2.009 gallery</a> (look for the build challenge links).</p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_D",
                slideshowTitle: "D) Ideate! Model! Test! Repeat.",
                slidesData: [
                    {
                        id: "gd1_D01",
                        number: "D1",
                        filename: "D01_2023ideateModelTestRepeat.webp",
                        altText: "ideate model test repeat",
                        videoInfo: {
                            playbackId: "Z5bZp6c01NZCObpsqdxz651ZqS7xv9eb18dOvgOKINZ4",
                            title: "Ideate! Model! Test! Repeat.",
                            thumbnailTime: "38",
                            metadata: {
                                video_id: "gd1_D01_2023_IMTR_video",
                                video_title: "Ideate! Model! Test! Repeat.",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "What's the mantra? ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">Ideate! Model! Test! Repeat.</p>

<p>And finally, what's the build challenge design mantra? <span class="text-italic">Ideate! Model! Test! Repeat.</span> Join Mildy and Wildy in the animated musical!</p>`
                    },
                ]
            },
        ]
    },
    "ep2": {
        preambleHTML: `<p>It's time for backstories about episode 2. I'm excited!</p>

<p><span class="text-italic">Have a plan, and be ready to change it.</span> That's hard. As designers, engineers, and doers in general, we have an idea &mdash; a plan. We need to fully believe in it and be all-in. Invested! But, at the same time, we need to be reflective, open to seeing limitations, and decisively change paths when better opportunities or solutions arise. How do we let go? How are we open to change, but also avoid bouncing around between options every time a difficulty arises? Should we persist on our path, or change course? How do we embrace a superior option that was somebody else's suggestion?</p>

<p>Wowee, this is heady stuff, but I do believe that <span class="text-italic">having a plan and being ready to change it</span> is essential for excellence. To keep growing. To keep improving to meet our goals. To be a good designer! We can always be better.</p>

<p>So, let's dig in!</p>`,
        slideshowsList: [
            {
                slideshowId: "ep2_A",
                slideshowTitle: "A: The origins of FNAP",
                slidesData: [
                    {
                        id: "gd2_A01",
                        number: "A1",
                        filename: "A01_workingOnLectureEarlyAM.webp",
                        altText: "Prof. Wallace in office in early morning",
                        blurb: "FNAP, the origin story! ...",
                        descriptionHTML: `<p>FNAP, the origin story!</p>

<p>It's between 2-3 AM of a fall 2017 morning. I'm working on lecture materials as the clock relentlessly ticks towards my deadline—a lecture starting at 1 PM of the same day. And I know something needs to be changed from last year. There's something that needs to be done better. Think!</p>

<p>It's the time in 2.009 where teams are about to make their final product choice, selecting from 4 separate opportunites on which subgroups (4-5 students) within each team have been working. Each team is roughtly 16-20 students.</p>

<p>The team has been working on four different options in parallel, ensuring rigour in the exploration of opportunities and increasing the odds of having a really promising final product direction.</p>`
                    },
                    {
                        id: "gd2_A02",
                        number: "A2",
                        filename: "A02_inLecture.webp",
                        altText: "a 2.009 lecture",
                        blurb: "And students have also been working ...",
                        descriptionHTML: `<p>And students have also been working in parallel subgroups to set up a powerful and very challenging learning experience! How does four sub-groups, each invested in a different option, come together as a single team and make an informed choice about what option has the greatest promise? How does each team member assess options with as little bias as possible, independent of who has worked on it?</p>

<p>In prior years, I'd try to prepare students by talking about being fact/data driven, being open to listening and internalizing different points of view. Really important. And hard! Sometimes it's really hard to know what's actually known fact vs what is simply believed. Without understanding that, how can risks and rewards be assessed? Strong beliefs, disguised as facts, can hold sway.</p>`
                    },
                    {
                        id: "gd2_A03",
                        number: "A3",
                        filename: "A03_difficultChoicesInLab.webp",
                        altText: "student in lab",
                        blurb: "So the elephant in the room ...",
                        descriptionHTML: `<p>So the elephant in the room during these decision meetings? Thoughts like... does that teammate really know this, or are they hoping that and mostly selling what they worked on? Or perhaps thoughts like... do I really know what I am stating as fact, or am I overreaching in claims because of my personal investment in the idea? These are questions that folks don't want to vocalize!</p>

<p>So, yes, difficult questions. I've long thought that pretending such concerns don't exist can lead to less than ideal outcomes.</p>

<p>So, I wanted to have a way to get concerns and different opinions out into the open. I wanted to find a way to make it socially acceptable to have an open discussion <span class="text-italic">in our own mind</span>. What in our thinking is fact, and what is hope or a hunch? And I wanted to provide a device that allowed teams to openly question how well something is understood, and how much is wishful thinking? To flag what seemed more like conjecture. How could such honest questioning become an accepted matter-of-course, a norm, in doing their business?</p>`
                    },
                    {
                        id: "gd2_A04",
                        number: "A4",
                        filename: "A04_FNAPinLab.webp",
                        altText: "FNAP posters in lab",
                        blurb: "What did we need? ...",
                        descriptionHTML: `<p>What did we need? My answer... an acronym! A catchy mnemonic that made it OK to openly wonder and ask out loud in a team setting. A mnemonic to remind ourselves to clearly represent what is fact as fact, and identify what we hope as hopes. To remind ourselves that having a confident hope (assertion) simply means there is more work to done before it becomes a fact (or not!).</p>

<p>So after a few minutes of noodling, FNAP (Facts Not Assertions, Please!) came to mind! Into the lecture it went, and onto the walls in the lab. Folks smiled, it stuck, and FNAP was born!</p>`
                    },
                    {
                        id: "gd2_A05",
                        number: "A5",
                        filename: "A05_MAplate.webp",
                        altText: "MA license plate",
                        blurb: "Acronyms and license plates...",
                        descriptionHTML: `<p>Acronyms and license plates. The origin story's origin. Or perhaps just a bit of an aside.</p>

<p>Here's a little game I've played to pass time while in traffic. I look at the plate on the car in front of me. As long as I can see that plate and as fast as I can, I think of different things that the letters on the plate could be an acronym for—the more amusing the better! When the plate goes out of sight, it's on to the next plate.</p>

<p>My favorite Massachusetts plates have the format 123-ABC or 1ABC-23. I prefer a three letter format. The game works for two letter plates, too, or even word associations for a single letter. But, I find three letters the most fun.</p>

<p>I'd like to believe that my big payoff for years of license-plate acronym-training was the creation of FNAP, under the pressure of a deadline!</p>`
                    },
                    {
                        id: "gd2_A06",
                        number: "A6",
                        filename: "A06_FNAP_IntroAnimation.webp",
                        altText: "FNAP animation",
                        videoInfo: {
                            playbackId: "67tYGfjDkXCkh6Yplh02IPcxz02kMwqpeKQ00cNNnfBUDs",
                            title: "FNAP animation",
                            thumbnailTime: "3.4",
                            metadata: {
                                video_id: "gd2_A06_FNAP_animation",
                                video_title: "FNAP animation",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "FNAP, the video! ...",
                        descriptionHTML: `<p>FNAP, the video!</p>

<p>During the pandemic I ended up making a number of video-based lectures for 2.00b (toy product design) and for 2.009. So back in person, in the fall of 2020 and spring of 2021, I started making animated shorts to kick-off the start of lectures in a positive way, while reinforcing an idea that I hoped would stick.</p>

<p>FNAP was a natural fit. In the fall of 2021, the FNAP animation was created in Blender—with Director Brian Cassin <a href="https://instagram.com/innerparakeet" target="_blank">@innerparakeet</a>, modeling help from graduate student Charlene Xia, and featuring the song writing and composing of longtime 2.009 friend Sam Watkinson  <a href="https://instagram.com/sam100watkinson" target="_blank">@sam100watkinson</a>. For the song vocals, Brian sent quick voice memo on Slack to Sam suggesting ideas about how the song might go. It began with "maybe something like this...". And that recording became the audio sample in the song!</p>

<p>Please take a look!</p>

<p>(You may have also watched the <a href="/BackStories/ep1#ep1_D">ideate-model-test</a> animation at the end of the back stories for episode 1. It was also developed as a lecture kickoff animation by the same team.)</p>`
                    },
                    {
                        id: "gd2_A07",
                        number: "A7",
                        filename: "A07_episodeIntroStoryboard.webp",
                        altText: "episode storyboard",
                        blurb: "The FNAP song ...",
                        descriptionHTML: `<p>The FNAP song turned out to be quite catchy, so we decided to use it to lead off the arcade-game dance-scene for this episode, <span class="text-italic">have a plan and be ready to change it</span>.</p>

<p>Director Brian Cassin's rough storyboard was used to guide the filming of the episode's introduction. The "game console" is something that we had originally made for use in the 2.009 shop &mdash; a kiosk for purchasing materials from our in-lab store &mdash; repurposed with FNAP graphics!</p>`
                    },
                ]
            },
            {
                slideshowId: "ep2_B",
                slideshowTitle: "B: Decisions",
                slidesData: [
                    {
                        id: "gd2_B01",
                        number: "B1",
                        filename: "B01_mediationWithMindy.webp",
                        altText: "mediation with Mindy",
                        blurb: "FNAP is a little reminder ...",
                        descriptionHTML: `<p>FNAP is a little reminder, a voice for our conscience, to help us stay grounded in our own thinking and when making choices with others.</p>

<p>But, is FNAP alone all that is needed to make your best decisions?  Probably not!</p>

<p>To support student teams in their 2.009 decisions there are a plethora of resources in place. We provide teams with an <a href="https://2023.2009.mit.edu/team-manual/team-roles" target="_blank">officer structure</a> that helps with management (system integrators) and team-member needs (yodas &mdash; an exciting title for a human resource position). In their labs, teams also have experienced faculty instructors, communication instructors, and mentors with product development experience. Plus, Mindy Garber, MechE alum, software developer, and professional mediator (shown above), holds a workshop on Moderating Design Disputes. Thanks, Mindy!</p>

<p>So will FNAP and all these support resources consistently lead to our best team choices? They sure do help, but still probably not enough!</p>

<p>What else do we need? Drum roll, please!... We need a decision process, and we need an acronym to remember it!</p>`
                    },
                    {
                        id: "gd2_B02",
                        number: "B2",
                        filename: "B02_GoodDay.webp",
                        altText: "sunny sky",
                        blurb: "G'DAY! ...",
                        descriptionHTML: `<p>G'DAY!</p>

<p>Another made up acronym, just like FNAP. It's to remind teams of the steps I'd like them to follow when making choices &mdash; and in particular the very difficult team choice of narrowing from 4 product options to their final direction.</p>

<p><span class="text-yellow" style="font-weight:bold;">G</span>ive. <span class="text-yellow" style="font-weight:bold;">D</span>ecide. <span class="text-yellow" style="font-weight:bold;">A</span>ccept. <span class="text-yellow" style="font-weight:bold;">Y</span>o-wup!</p>

<p>(This graphic is from actually one of my lecture slides in 2.00b &mdash; we want every lecture be a g'day, too!)</p>`
                    },
                    {
                        id: "gd2_B03",
                        number: "B3",
                        filename: "B03_ExplainingToTeam.webp",
                        altText: "team discussion",
                        blurb: "Give. ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">G</span>ive.</p>
<p>Our first job is to freely give information and express our thoughts. With FNAP on our minds, we want to ensure that all members of the team are well informed about the options. We want to build an agreed upon understanding of where an option is at, what's its potential, what's the likely road ahead, and what are its risks.</p>

<p>We need to teach each other about all the options. We know what we've worked on ourselves, but much less about what others have done. I'll often ask sub-groups to be responsible for teaching the team about an option that they themselves did not work on! So they first need to meet with the group that worked on the option, learn about it, and then come to an agreement on how to factually GIVE the information about the option to the rest of the team!</p>

<p>Once the team has been well informed of the options, the team needs to agree that they are sufficently informed and understand what is fact, and what are hopes and beliefs. The knowns and unknowns.</p>`
                    },
                    {
                        id: "gd2_B04",
                        number: "B4",
                        filename: "B04_greenStartingPugh.webp",
                        altText: "pugh charting",
                        blurb: "Decide. ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">D</span>ecide.</p>
<p>Like it or not, we are a point in our product development process where we need to commit. To decide on our most promising option as a final product direction. If we don't, we're going to miss our delivery date!</p>

<p>There are lots of decision tools that might be used. We often use a Pugh chart and then vote. It's a simple tool to visually compare options and facilitate a concrete discussion summarizing goals, pros, and cons, before selecting.</p>`
                    },
                    {
                        id: "gd2_B05",
                        number: "B5",
                        filename: "B05_greenAfterDecision.webp",
                        altText: "happiness after making decision",
                        blurb: "Accept. ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">A</span>ccept.</p>
<p>And when is a decision made?</p>

<p>When there's consensus that the decision is made, and we can live with it. Given all the unknowns at this stage, there will almost always be differing opinions about what's the best product opportunity.  That's fine, but it's important that team members agree that the choice has been made fairly and respectfully.</p>

<p>We all need to Accept the decision.</p>`
                    },
                    {
                        id: "gd2_B06",
                        number: "B6",
                        filename: "B06_tejimeExample.webp",
                        altText: "Japanese tejime example",
                        videoInfo: {
                            playbackId: "YgEF6yuvUdM1m1DtxiGaVP02BGhBFZjMh8xLWMIdPEAg",
                            title: "Japanese tejime example",
                            thumbnailTime: "6",
                            metadata: {
                                video_id: "gd2_B06_JP_tejime",
                                video_title: "Japanese tejime example",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Yo-Wup! ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">Y</span>o-Wup!</p>

<p>Yo-What? We need something to symbolize that we have come to a decision about our product, and to mentally move on united in our mission. A cathartic way to change gears and look forward together.</p>

<p>And for this, I've respectfully adopted a Japanese hand-clapping ritual called <a href="https://en.wikipedia.org/wiki/Tejime" target="_blank">Tejime</a>! It's a cathartic release which signifies that an important decision has been made, or an important event has occured, and we are moving forward. Closure. We are united.</p>

<p>And I believe that it really works.</p>`
                    },
                    {
                        id: "gd2_B07",
                        number: "B7",
                        filename: "B07_blueTeamProductDecisionTejime.webp",
                        altText: "2.009 team tejime example",
                        videoInfo: {
                            playbackId: "TonIkdgmcqtSHrxgUsMKr6p95brpa8JYbpSbA2V01AGQ",
                            title: "2.009 blue team tejime",
                            thumbnailTime: "2",
                            metadata: {
                                video_id: "gd2_B07_team_tejime",
                                video_title: "2.009 blue team tejime",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "I set up context for the Tejime ...",
                        descriptionHTML: `<p>I set up context for the Tejime in lecture. As a class in lecture &mdash; we practice!
And here's Blue Team's yo-wup after a 3 hour process to decide upon their final product direction! It brings a smile, a feeling of accomplishment, and a release after a difficult process.</p>`
                    },
                    {
                        id: "gd2_B08",
                        number: "B8",
                        filename: "B08_finalYoWup.webp",
                        altText: "2.009 final prototype launch tejime example",
                        videoInfo: {
                            playbackId: "pXMwQ8jjHZSW8OeDn8201OeBny1GndyUgryGZ501FqqNo",
                            title: "2.009 final prototype launch tejime",
                            thumbnailTime: "26.73",
                            metadata: {
                                video_id: "gd2_B08_final_launch_tejime",
                                video_title: "2.009 final prototype launch tejime",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "So, that's the decision process. ...",
                        descriptionHTML: `<p>So, that's the decision process.</p>
<p><span class="text-yellow" style="font-weight:bold;">G</span>ive. <span class="text-yellow" style="font-weight:bold;">D</span>ecide. <span class="text-yellow" style="font-weight:bold;">A</span>ccept. <span class="text-yellow" style="font-weight:bold;">Y</span>o-wup! Follow these steps and you'll have a <span class="text-yellow" style="font-weight:bold;">G'day</span>! Really!</p>

<p>Now, if there's a 2.009 event meriting a yo-wup, this would be it &mdash; the end of 2.009 and the conclusion of our final prototype launch! Here we are in the fall of 2023, the same year that Play Seriously was recorded.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep2_C",
                slideshowTitle: "C is for Closure",
                slidesData: [
                    {
                        id: "gd2_C01",
                        number: "C1",
                        filename: "C01_HifanaTejime.webp",
                        altText: "Hifana Tejime",
                        videoInfo: {
                            playbackId: "dpLQTUvGAQe2aejGe4Vc2ke01NUOIP4G8TQL01DgUTxkk",
                            title: "Hifana Tejime",
                            thumbnailTime: "4",
                            metadata: {
                                video_id: "gd2_C01_Hifana_Tejime",
                                video_title: "Hifana Tejime",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "And to close out our episode 2 backstories, ...",
                        descriptionHTML: `<p>And to close out our episode 2 back stories, here's a video that I use at the end of the class where I introduce the Tejime and G'DAY. It's right before teams will be making their product choices in lab.</p>

<p>The video is by the Japanese band <a href="https://www.youtube.com/user/HifanaTube" target="_blank">Hifana</a>. It's their Tejime, bidding farewell to the Heisei era (1989-2019), a time when the transition from analog to digital music gave rise to new and innovative means of expression. And it's their goodbye to digital music. It's pretty fun!</p>

<p>You should be able to recognize the clapping ritual in it. I shortened the original video a bit for use at the end of class and added the Tejime cover at the start.</p>`
                    },
                    {
                        id: "gd2_C02",
                        number: "C2",
                        filename: "C02_2020FinalPresCountdown.webp",
                        altText: "Introduction animation for 2020 2.009 final prototype launch",
                        videoInfo: {
                            playbackId: "wGS5lKYSjtnUuHCjHiNy8gGlBC9XcJoUAzkofUCZ1s8",
                            title: "2020 2.009 Final Prototype Launch Introduction Animation",
                            thumbnailTime: "10",
                            metadata: {
                                video_id: "gd2_C02_FP_intro_animation",
                                video_title: "2020 2.009 Final Prototype Launch Introduction Animation",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Oh, and just one more video. ...",
                        descriptionHTML: `<p>Oh, and just one more video. This is the welcome animation to introduce the 2020 final prototype launch.</p>

<p>2020. The pandemic. 2.009 was one of the handful of classes in-person-on-campus that fall. Lots of dark hallways and unheated classrooms. Our design theme was for the year was <span class="text-italic">Kindness</span>, and you should be able to see and hear the Hifana and Tejime inspiration in this animation, especially at 1:00s. Yes, I'm a fan!</p>

<p>In addition to introducing the theme for the year, the animation also alludes to the measures taken to work together in person, in teams, in lab developing our products at the height of the pandemic. Medical-level PPE and health scans to access the lab! The animation was directed by former 2.009 teaching assistant Or Oppenhiemer (thanks Or!) with animation help by our 2020 teaching assistants (all former 2.009 students). The original music is by our 2.009 friend Sam Watkinson <a href="https://instagram.com/sam100watkinson" target="_blank">@sam100watkinson</a>.</p>`
                    },
                ]
            },
        ]
    },
    "ep3": {
        preambleHTML: `<p>Make students' stars shine! For teachers, this seems pretty obvious, no? But maybe it's not. As doers, we are always working to be masters of our craft and to showcase what we do well. To excel in what we do. And certainly for myself &mdash; just to survive as an MIT professor &mdash; I needed to pretty much single-mindedly commit to excellence and to doing the work that made my own star shine bright.</p>

<p>So how does one get past always driving hard to better oneself and showcasing it, to being just as driven while taking the back seat and providing opportunities for others? To help others' stars shine. To really teach.</p>`,
        slideshowsList: [
            {
                slideshowId: "ep3_A",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_A01",
                        number: "A1",
                        filename: "A01_starsShineInEyes.webp",
                        altText: "students with shining eyes",
                        blurb: "Quite early in my career, ...",
                        descriptionHTML: `<p>Quite early in my career, I was attending a guest lecture and thinking "this is great!" I was watching nuanced technique and was comparing with my own. I was marvelling at the speaker's talent and mastery. Thinking about how I might try to change. I excitedly walked out of the lecture, and exclaimed to a student "wasn't that great!" And the student's response? "No, I'm so discouraged. I'll never be able to do anything like that."</p>

<p>And then, a light went on in my head! Yes, to teach I really did need to excel at my craft, and always work to be better. And I needed to bring that mastery to students. But I needed to do it in a way that wasn't a showcase of my own talent or how much skill something required. I needed to present and explain in a way that didn't require mastery just to relate with the topic. To teach, I needed to make it feel accessible. I needed to teach in a way that allowed every student to believe they can do it too. Because they can. To believe that with effort, they would be just as masterful, or better. I needed to make challenging things seem matter-of-fact. Make the complex seem clear and simple. I needed to be really good with enthusiam, not pretense! I needed to have the self-confidence in my own abilities to help others feel that they will be the master!</p>

<p>It was after this guest lecture and student interaction that I wrote down into my notebook "my job is to make students' stars shine". And I can say with complete earnestness that the most fullfilling moments in my professional experience have been seeing that transition when students' stars turn on and shine bright. In this picture, backstage, you can see the starlight in Pink team members' eyes. And that's when I feel my own star shine.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep3_B",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_B01",
                        number: "B1",
                        filename: "B01_gatesOfHell.webp",
                        altText: "natural gas crater - gates of hell",
                        blurb: "It's a fiery natural gas crater ...",
                        descriptionHTML: `<p>It's a fiery <a href="https://www.cnn.com/travel/turkmenistan-flaming-gates-of-hell-darvaza/index.html" target="_blank">natural gas crater in Turkmenistan</a> commonly referred to as the "gates of hell". Scary and captivating at the same time.</p>


<p>The gates of hell. Mythological entrances to the underworld. Historically, approaching the gates is oft associated with confronting peril in a noble quest.</p>

<p>OK, it's fiery. What does this have to do with <span class="text-italic">Play Seriously</span> and stars shining bright?</p>`
                    },
                    {
                        id: "gd3_B02",
                        number: "B2",
                        filename: "B02_wildMechanicalRevealGates.webp",
                        altText: "the 2.009 final prototype launch reveal gates",
                        videoInfo: {
                            playbackId: "V004E3Avf00etFvB9YYerM7NJNVSdy8AVKBKcUs6td00200",
                            title: '2.009 "Wild" Gates of Hell',
                            thumbnailTime: "38",
                            metadata: {
                                video_id: "wild_gates_of_hell",
                                video_title: '2.009 "Wild" Gates of Hell',
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Make students' stars shine bright features ...",
                        descriptionHTML: `<p><span class="text-italic">Make Students' Stars Shine Bright</span> features the 2023 2.009 final prototype launch event. The year's design theme is <span class="text-italic">Wild</span> and you saw the "wild" mechanical gates that opened to reveal teams on stage. These are the gates that we metaphorically call "the gates of hell".</p>

<p>The gates provide students with cover so they can set up upstage and be ready for a crisp, clean start to their presentation. Of course, students practice getting ready behind the gates, working out blocking and how to make their entrance at the moment of reveal. We (the staff) also need to practice opening and closing the gates to make it look smooth and easy. Synchronizing them and moving on a tight stage is actually not that easy! We have a short window to setup for the final launch event, so the gates are assembled, detailed, and undergo final debugging while students practice. There's a lot of parts moving at once.</p>`
                    },
                    {
                        id: "gd3_B03",
                        number: "B3",
                        filename: "B03_gatesClosed.webp",
                        altText: "the 2.009 reveal gates closed",
                        blurb: "Pink Team is about to begin. ...",
                        descriptionHTML: `<p>Pink Team is about to begin. Anticipation builds as team members are featured in video, while Pink Team readies behind the gates.</p>`
                    },
                    {
                        id: "gd3_B04",
                        number: "B4",
                        filename: "B04_gatesBehind.webp",
                        altText: "behind the 2.009 reveal gates",
                        blurb: "The gates of hell open for Blue Team. ...",
                        descriptionHTML: `<p>The gates of hell open for Blue Team. Students courageously face their fears in an inspired quest to launch their product prototype.</p>`
                    },
                    {
                        id: "gd3_B05",
                        number: "B5",
                        filename: "B05_gatesOpening.webp",
                        altText: "the 2.009 reveal gates opening",
                        blurb: "As the gates open, the students' stars ...",
                        descriptionHTML: `<p>As the gates open, the students' stars shine bright through the gate, spilling light into the yonder!</p>`
                    },
                    {
                        id: "gd3_B06",
                        number: "B6",
                        filename: "B06_gatesOpen.webp",
                        altText: "the 2.009 reveal gates open",
                        blurb: "Purple team steps through the gates. ...",
                        descriptionHTML: `<p>Purple team steps through the gates. Their light emanates through the knowledge that they have risen to their challenge and are transformed.</p>`
                    },
                    {
                        id: "gd3_B07",
                        number: "B7",
                        filename: "B07_blueTeamOnStage.webp",
                        altText: "team after presentation",
                        blurb: "The conclusion of Blue Team's prototype ...",
                        descriptionHTML: `<p>The conclusion of Blue Team's prototype launch presentation. They've courageously overcome challenges and succeeded in their quest, as a team. Indeed, their stars are shining.</p>

<p>So, that's the mechanical reveal gates. The gates of hell. Lights. Cryo. Kind of cool, yes? And now you know the idea that was behind them!</p>`
                    },
                ]
            },
            {
                slideshowId: "ep3_C",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_C01",
                        number: "C1",
                        filename: "C01_2.009toAvive.webp",
                        altText: "Avive AED device",
                        videoInfo: {
                            playbackId: "TNBBRw6sHjNNdI004Z602sZIwBaqSYrFEGP3zzuNufQZo",
                            title: "From 2.009 to Avive",
                            thumbnailTime: "97",
                            metadata: {
                                video_id: "from_2009_to_avive",
                                video_title: "From 2.009 to Avive",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Play Seriously focuses on the philosophy ...",
                        descriptionHTML: `<p><span class="text-italic">Play Seriously</span> focuses on the philosophy behind the effort to create learning environments where folks thrive and do good work. We use video from the class 2.009 to bring concepts to life. However, the ideas are widely applicable beyond this class, and so I didn't focus on the specifics of student work in this series. That said, when students' stars are shining, they do some pretty cool work. I figured that I would showcase some projects here, and will also showcase more in future episode backstories as well.</p>

<p>Here, I'll feature two products that are now commercially available. In our class, the focus is to set students up for the rest of their career. Our goal is to build a solid foundation and desire. That said, I often say that the best learning occurs when we "do it for real". We treat the learning experience as a design opportunity to do something real &mdash; that can make a difference. When a project from the class leads to a commercially available product, it's a bonus activity and just testament that the students are really doing it for real.</p>

<p>In this first video, we feature a product coming from Pink Team in the fall of 2016. In class, students named their product "Revive", and after the class students started the company <a href="https://avive.life" target="_blank">Avive</a> launching the product "QuickRescue". If you are on the MIT campus, you'll find their AED in many locations.</p>

<p>This video was shown during the pre-show of the 2.009 prototype launch in the fall of 2023.</p>`
                    },
                    {
                        id: "gd3_C02",
                        number: "C2",
                        filename: "C02_2.009to6dot.webp",
                        altText: "6dot braille label maker",
                        videoInfo: {
                            playbackId: "NeoJwKQ1penLIO6DQAaIk33zE7WMgGkP5LM23f9Fhuk",
                            title: "From 2.009 to 6dot",
                            thumbnailTime: "83",
                            metadata: {
                                video_id: "from_2009_to_6dot",
                                video_title: "From 2.009 to 6dot",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Here's another commercial product ...",
                        descriptionHTML: `<p>Here's another commercial product that stemmed from Blue Team many years ago. It's a Braille labeler and, if you are interested, you can buy one!. It went from a 2.009 prototype named "dot-it", to a limited run product on Kickstarter, to being purchased and sold by an <a href="https://logantech.com/products/6dot-braille-label-maker" target="_blank">assistive device company under the name 6dot</a>.</p>

<p>This video was also produced and shown in the pre-show of the 2.009 prototype launch when we recorded <span class="text-italic">Play Seriously</span> (fall of 2023). The video also is a tribute to Paul Parravano, longtime MIT community member, who was a lead user for Blue Team on their project. He passed away shortly before the 2023 presentations. Paul's voice in the video comes directly from the team's class presentation.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep3_D",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_D01",
                        number: "D1",
                        filename: "D01_2016_FP_Kresge_Timelapse.webp",
                        altText: "Kresge setup before the presentations",
                        videoInfo: {
                            playbackId: "4ph7QTHRtLXwI2od4QWTqPTQB4MBH1DYK9jzuTWsFIc",
                            title: "2016 2.009 Final Presentation Kresge Setup Timelapse",
                            thumbnailTime: "19",
                            metadata: {
                                video_id: "2016_Kresge_setup_timelapse",
                                video_title: "2016 2.009 Final Presentation Kresge Setup Timelapse",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "To close it out, here is a time lapse video ...",
                        descriptionHTML: `<p>To close it out, here is a time lapse video of setting up for the prototype launch in Kresge, in December of 2016. It's a long time ago, and while a lot of things in the setup had changed by fall 2023, the founding ideas are similar. In the slideshow towards the end of the video, you certainly will see students' stars shining.</p>`
                    },
                ]
            },
        ]
    },
    "ep4": {
        preambleHTML: `<p>Many years ago, in the early 1980s, I was an undergraduate student in Industrial Design at <a href="https://carleton.ca/" target="_blank">Carleton University</a> in Canada. My interest in product design led me to an undergraduate degree in Industrial Design and then a degree in Mechanical Engineering.  And, finally, graduate studies at MIT.</p>

<p>I recall a curious comment that my fourth year studio advisor, Professor Brian Burns, made to me while I was studying Industrial Design. He said, "I like that when you're stuck you always ask me for design advice, and then you never do what I say". Curious, for sure.</p>

<p>I came to realize that what he meant was... Truely absorb information. Play with it in your mind and your thinking will open new possibilities. Transformed, you will come up with your own, better, plan. His advice was just a prompt intended to open pathways for thought. A tool for opening new pathways for learning.</p>

<p><span class="text-italic">Everyone has something to teach</span>. This is a principle that is a reminder to myself as much as anything else. A reminder to really observe the world. To pay attention. To have the eyes-wide-open curiosity of a child. To see, hear, smell, feel, and taste the world around me. And this also includes processing different points of view, even when I really think otherwise myself. In doing so, I will gain insight and learn. And, as a result, I will also be a better designer. I wrote down this principle after a meeting that included a person that I had come to very much discount and tune-out when they spoke. And in that meeting... they said something that really turned on a light in my head. Later, as I thought about it a bit more, I humbly wrote <span class="text-italic">"everyone has something to teach"</span>.</p>

<p>In today's backstory, I'll highlight a couple of 2.009 product prototypes that had a very strong connection to a user, which required empathy to learn from users. I also plan on showing project examples in the next few backstories. That said, I want to emphasize that class is about learning. My hope is to help set students onto a pathway for realizing their own goals in their own careers. We hope students will see their work in 2.009 as real, and that the goal is much more than the artifact produced by the end of the term.</p>`,
        slideshowsList: [
            {
                slideshowId: "ep4_A",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd4_A01",
                        number: "A1",
                        filename: "A01_animoShort.webp",
                        altText: "User demonstrating Animo vibration therapy device",
                        videoInfo: {
                            playbackId: "3VIKfwJIUXCYq5gDxAIa2Qd2fWNBP1YULpwjHjSHQN8",
                            title: "2.009 Animo presentation - short version",
                            thumbnailTime: "82",
                            metadata: {
                                video_id: "animo_short",
                                video_title: "2.009 Animo presentation - short version",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Animo. Purple team in 2017. ...",
                        descriptionHTML: `<p>Animo. Purple team in 2017.</p>

<p>The year's overall design theme was <span class="text-italic">"Super"</span>. Products that enable superpowers!</p>

<p>Purple team's focus was on hands. While exploring product opportunities, a team member came across a white paper about hand tremor and vibration therapy. The paper was pretty vague and rather incomplete. Working on the problem would be better suited to a long term R&D project than a 3 month product development activity.  Taking it on was a huge risk. But what a great place to take that risk! At school, in a place where it's all about learning and, even if it did not work, it would still be an educational success! The team chose this opportunity with a "let's go for it" attitude, treating it "for real" and as if the stakes were high. Understanding and empathizing with who you are designing for is a powerful motivator. Plus the hardware, at the minimum, could be a testing platform for vibration therapy.</p>

<p>This condensed version of the Purple team's presentation was prepared for showing during the pre-show of our fall 2023 prototype launch event.</p>`
                    },
                    {
                        id: "gd4_A02",
                        number: "A2",
                        filename: "A02_elevateShort.webp",
                        altText: "User demonstrating Elevate device",
                        videoInfo: {
                            playbackId: "SkKq8nPQNuAMGA2YI00iEctRe9SM00arBjORYL2usSpxQ",
                            title: "2.009 Elevate presentation - short version",
                            thumbnailTime: "42",
                            metadata: {
                                video_id: "elevate_short",
                                video_title: "2.009 Elevate presentation - short version",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Elevate. Green team in 2018. ...",
                        descriptionHTML: `<p>Elevate. Green team in 2018.</p>

<p>Green team was inspired by a classmate, and how being at a different elevation than one's peers can impact interpersonal dynamics. And, in turn, this can shape perceptions of self and the pre-conceptions of others (the year's theme was <span class="text-italic">"Danger"</span>).</p>

<p>Interestingly, assistive devices such as walkers, canes, and wheelchairs are popular choices for student design projects. If one looks at such devices, they seem deceptively simple. And this makes them really difficult devices to design. They need to be light, efficient, easy to use, plus a lot of smart people around the world have thought about them. All the details are really important and, as minimal structures, there's nowhere to hide!</p>

<p>This video short of green team's presentation was prepared for a 2.009 lecture where I show students examples of prototype presentations from prior years.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep4_B",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd4_B01",
                        number: "B1",
                        filename: "B01_WintergatanMarbleMachine.webp",
                        altText: "Wintergatan Marble Music Machine",
                        videoInfo: {
                            playbackId: "a02Mf5U9EK6m01Rho4KN7Mlo8qKKFu02o015qd601adPErSM",
                            title: "Wintergatan Marble Machine",
                            thumbnailTime: "136",
                            metadata: {
                                video_id: "wintergatan_marble_machine",
                                video_title: "Wintergatan Marble Machine",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Writing the preamble for this episode's ...",
                        descriptionHTML: `<p>Writing the preamble for this episode's backstory &mdash; and then discussing the minimal "simple" design of assistive devices got me reflecting further on my 4th year studio advisor, Brian Burns.</p>

<p>Another thing that I recall him saying to me was "Have the courage to do what makes sense!" In product design, what does this mean? I think it means... do what meets the need with the highest reliability and minimum resources. And that what makes the most sense may not be what we, the designers, think is the coolest. This requires discipline!</p>

<p>In the same context, he spoke of the idea of "University Design". As technologists we like (perhaps love!) complexity. It's really cool to do something just because it is hard. We like to showcase our mastery. And, of course, there's a place for this &mdash; exploring boundaries and pushing limits. We just need to recognize it as such. When designing products for mass production, we want to find the cleanest, most elegant "simple" solution for a problem &mdash; whether that problem is visionary or mundane. And doing this is pretty hard, too. It's just not obviously complex.</p>

<p>I've included this marble music machine video by the band <a href="https://en.wikipedia.org/wiki/Wintergatan" target="_blank">Wintergatan</a>, also on <a href="https://www.youtube.com/watch?v=IvUU8joBb1Q" target="_blank">You Tube</a> for a couple of reasons.</p>

<p>
<ol class="gd-lroman-list">
<li>It's pretty cool! I show it for fun at the end of one of my 2.009 lectures on making sketch models.</li>
<li>One has to appreciate the complexity, imagination, and mastery required to execute this vision.</li>
<li>It helps to remind me that "having the courage to do what makes sense" is very dependent on context and our end goals.</li>
</ol>
</p>

<p>If you are interested, I've also long thought that these wind-powered kinetic sculptures made of wood, PVC, and zip ties called <a href="https://www.youtube.com/watch?v=C97kMKwZ2-g" target="_blank">Strandbeests</a> (Dutch for beach animals) by <a href="https://en.wikipedia.org/wiki/Theo_Jansen" target="_blank">Theo Jansen</a> are pretty captivating. There's so many cool things out there!</p>`
                    },
                ]
            },
            {
                slideshowId: "ep4_C",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_C01",
                        number: "C1",
                        filename: "C01_FinneganAndFamily.webp",
                        altText: "Finnegan and family",
                        blurb: "And finally, what about Finnegan? ...",
                        descriptionHTML: `<p>And finally, what about Finnegan? Here's Finnegan with my son, Donald, and mom, Elaine.</p>

<p>The star of <span class="text-italic">Everyone has something to teach</span> is a 4 year old toy poodle. Finnegan was my sister's buddy. Kim passed away from duodenal cancer early in the fall of 2023, 7 days before the build challenge that was featured in episode 1. Finnegan joined my family in Boston on the weekend of Canadian Thanksgiving (Indigenous People's Day in the US) following the build challenge.</p>

<p>When I was a child my family had a toy poodle. As adults, my brother has standard poodles, I had a mini poodle, and my sister had toy poodles. A poodle family!</p>`
                    },
                ]
            },
        ]
    },
    "ep5": {
        preambleHTML: `<p>In the series we've tried to make each episode have a different flavor while being unified by the concept of playing seriously. <span class="text-italic">Goals are Essential</span> is a <span class="text-italic">how-to</span> episode &mdash; how to get things done successfully. It's not uncommon to have big dreams but become overwhelmed by how to get there. Break the daunting into digestable chunks, with lots of in-between markers and achievements. Sometimes we might think "someday I'll...". But if we don't have a plan on how to get started, will we get started? We can always be working towards our goals in some way. Have a plan (and be ready to change it! &mdash; <a href="/#ep2">episode 2</a>). Start now, not someday.</p>

<p>We can develop and practice a standardized procedure, or process, for working towards goals. The goals and problems may change but our process for getting there can be organized and well rehearsed. A drill. Something that we can become very accomplished at executing.</p>

<p>And that's what we will explore in episode 5's backstories.</p>`,
        slideshowsList: [
            {
                slideshowId: "ep5_A",
                slideshowTitle: "The Scheduling Jam",
                slidesData: [
                    {
                        id: "gd5_A01",
                        number: "A1",
                        filename: "A01_schedulingJam.webp",
                        altText: "scheduling jam video",
                        videoInfo: {
                            playbackId: "KkvnrrPMWUiWpITB9U7lcnmpzvLzIaIDmz9T5KFT2iQ",
                            title: "2.009 Scheduling Jam",
                            thumbnailTime: "60",
                            metadata: {
                                video_id: "2023_scheduling_jam",
                                video_title: "2.009 Scheduling Jam",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "The scheduling jam. ...",
                        descriptionHTML: `<p><span class="text-italic">The scheduling jam</span>.</p>

<p>Let's start things off with a fun mnemonic. This short video was made for the start of a 2.009 lecture on scheduling and project management and has some fun with tools we introduce in the class. It's in the same conceptual block where I introduce the chicken chart shown in the episode. Like the chicken chart, it's a reminder that the journey to our goals is a balance of strategically metering our finite, tangible resources (like the egg yolk) and the unrelenting progression of time to reach our desired "finished" state (like the fixed, 21 day gestation period for a chicken's egg).</p>

<p>I also believe that things are never really done. With more time, we can always improve something, becoming better adapted as we grow and learn more. The world is always changing, and we need to change with it! So the game is, within available time, to get as far as we can on our improvement path, utilizing our resources to their fullest. Never standing still.</p>

<p>Like the previous animated shorts (<span class="text-italic">Ideate! Model! Test! Repeat.</span> <a href="ep1#ep1_D1">(Ep1, D1)</a> and <span class="text-italic">FNAP</span> <a href="ep2#ep2_A6">(Ep2, A6)</a>), this video was produced by Brian Cassin <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a>, and the music was composed by Sam Watkinson <a href="https://instagram.com/sam100watkinson" target="_blank">@sam100watkinson</a>. Enjoy!</p>`
                    },
                ]
            },
            {
                slideshowId: "ep5_B",
                slideshowTitle: "Our process in 2.009!",
                slidesData: [
                    {
                        id: "gd5_B01",
                        number: "B1",
                        filename: "B01_greenTeam_2019_cover.webp",
                        altText: "2019 Green Team prototype",
                        videoInfo: {
                            playbackId: "MMLMjj6hpO02uP4kDSbAVH00tNsUXA017YNT9hdBuF76xA",
                            title: "2.009 2019 Green Team Final Presentation Short",
                            posterURL: "https://playseriously.com/back_stories/ep5/B01_greenTeam_2019_cover.png",
                            metadata: {
                                video_id: "2.009_2019_Green_FP_short",
                                video_title: "2.009 2019 Green Team Final Presentation Short",
                                viewer_user_id: "ps_website"
                            },
                        },
                        videoInfo2: {
                            playbackId: "wCoVcfcrernbaB00XSXOGoZfB00E1L6xU9W2gZ4m201RDI",
                            title: "2.009 2019 Process featuring Green Team",
                            thumbnailTime: "7",
                            metadata: {
                                video_id: "2.009_2019_process_video",
                                video_title: "2.009 2019 Process featuring Green Team",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Our process in 2.009! ...",
                        descriptionHTML: `<p><span class="text-italic">Our process in 2.009!</span></p>

<p>The chicken chart is a device to visualize that precarious balance we need to achieve between our goals, our resources, and time. Now, let's get a bit more specific about the standardized product development process that was developed and refined over the years in 2.009. The intent of the process is to ensure that we achive our best outcomes towards a goal under our constraints, consistently pushing as far as we can without a big miss (or what I called being fried in the alternative, undesired ending for the chicken chart!).</p>

<p>Here's a high speed visualization of the procedure, or <span class="text-italic">process</span> that we practice in 2.009 for realizing our product development goals in a 3 month term.</p>

<p>Above is a video short from the 2019 Green Team's final presentation and product prototype. Their product, Areta, is a belaying/climbing safety device. First, please watch the video so you'll have a feeling for their product and their end goal in the class.</p>

<p>OK, you know what the 2019 Green Team did! If you want to understand how it works, that will be explained in panel <a href="#gd5_C07">C7</a>.</p>

<p>Now, please watch the video below and experience how Green Team got there. It takes you on a journey following their process, beginning with opportunity identification and then, through idea exploration, concept development, detailed design, and prototype execution. You'll be guided through their steps by the character Iggy Sparkdust. In 2019, the year's theme was <span class="text-italic">"Ignite"</span> and I became Iggy Sparkdust for the term, drawing inspiration from David Bowie's <a href="https://www.youtube.com/watch?v=na8xgu-KLAk" target="_blank">Ziggy Stardust and the Spiders from Mars</a>. The colors in the vest pay tribute to the team colors in 2.009 &mdash; red, green, blue, yellow, orange, purple, pink and silver!</p>

<p>The video was produced by Brian Cassin <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a> for our 2019 prototype launch event. It's in a 4:1 aspect ratio because it was designed for playing on a 80'x20' screen that spanned the stage of Kresge Auditorium. The lyrics, written to the tune of the <a href="https://www.google.com/search?q=pirates+of+penzance+major+general" target="_blank">Major-General song from Pirates of Penzance</a>, were written by myself and Elaine Yang. I like to think that the song feels as exciting and exhausting as a term in 2.009! The music was produced by <a href="https://www.facebook.com/larrydersch" target="_blank">Larry Dersch</a>. Larry brings together the MITfits band that plays live at our final prototoype launch. Thanks, Larry, for your help over the years!</p>`
                    },
                    {
                        id: "gd5_B02",
                        number: "B2",
                        filename: "B02_silverTeam_2017_cover.webp",
                        altText: "2017 Silver Team test client",
                        videoInfo: {
                            playbackId: "dR802Z004NsvsX801qe4HgqUyI025INEfsBqllLBBVYjF1o",
                            title: "2.009 2017 Silver Team Final Presentation Short",
                            posterURL: "https://playseriously.com/back_stories/ep5/B02_silverTeam_2017_cover.png",
                            metadata: {
                                video_id: "2.009_2017_Silver_FP_short",
                                video_title: "2.009 2017 Silver Team Final Presentation Short",
                                viewer_user_id: "ps_website"
                            },
                        },
                        videoInfo2: {
                            playbackId: "cCbBVphmWQEE6z1LSy4jz78mNV1Xnk2QoeAaaYLUlTo",
                            title: "2.009 2017 Process featuring Silver Team",
                            thumbnailTime: "6",
                            metadata: {
                                video_id: "2.009_2017_process_video",
                                video_title: "2.009 2017 Process featuring Silver Team",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Got it? Like the green team's process ...",
                        descriptionHTML: `<p>Got it? Just like the process, the video moves quickly and there are lots of steps. Let's try another pass at it!</p>

<p>Above is a video short of Silver Team's final presentation in 2017. Their product, called FireSense, is a device that fire-fighters can use in buildings when deciding if it is safe to open doors. The year's 2.009 theme was <span class="text-italic">"Super"</span> and Silver Team focused on "senses".</p>

<p>Alrighty, now let's follow Silver Team through their process from opportunity identification to product prototype. The video below provides another take on the same process protrayed in green team's "Iggy Sparkdust" process video. Silver Team worked closely with the Cambridge Fire Department, and in the video, you'll see firefighters testing their device in a controlled building burn!</p>

<p>The video was animated and edited by Charlene Xia <a href="https://www.instagram.com/barrelofchuckles" target="_blank">@barrelofchuckles</a>. At the time, Chuck was a TA for 2.009 and Chuck is currently a PhD. Candidate at MIT. Chuck has been an important contributor to 2.009 and 2.00b (Toy Product Design) for many years. Thanks, Chuck!</p>`
                    },
                ]
            },
            {
                slideshowId: "ep5_C",
                slideshowTitle: "The 2.009 product development timeline",
                slidesData: [
                    {
                        id: "gd5_C01",
                        number: "C1",
                        filename: "C01_processWorkflow_2023.webp",
                        altText: "2.009 process workflow",
                        blurb: "The 2.009 product development timeline. ...",
                        descriptionHTML: `<p><span class="text-italic">The 2.009 product development timeline.</span></p>

<p>Finally, here's the chart representing the process workflow from 2023. It's a variation of the process that you saw in Silver Team's 2017 process video and Green Team's 2019 process video. It's a variation, because it's not exactly the same. In the search to learn and improve, I rarely do the exact same thing twice &mdash; one can always get better. I believe that if we have something really good today but don't keep trying to improve it, that same thing will be kind of "meh" in 5 or 10 years. Continuous improvement is not the easy path, but I think it is really important if one wants to stay relevant and on top of one's game!</p>

<p>I'll explain the chart in the following panels.</p>`
                    },
                    {
                        id: "gd5_C02",
                        number: "C2",
                        filename: "C02_green2019IdeaA3.webp",
                        altText: "Green Team member presenting an idea",
                        filename2: "C01_processWorkflow_2023.webp",
                        altText2: "2.009 process workflow",
                        videoInfo: {
                            playbackId: "qnB9Kpw2HMxD35l5nMtMUpyAk2uFhdwMmjede4XqXRE",
                            title: "2019 Green Team idea - Fire Work",
                            thumbnailTime: "17",
                            metadata: {
                                video_id: "2.009_2019_Green_IdeaA3",
                                video_title: "2019 Green Team idea - Fire Work",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Ideas Pitch. ...",
                        descriptionHTML: `<p>Ideas Pitch.</p>
<p>The first part of the process is about searching for good opportunities for products. Problems that might be addressed through a product. The name of the game is to be rigorous in the breadth of our exploration, and we split each team into quasi-independent halves to independently explore and pitch ideas. Each team pitches a total of 6 directions (3 per team half) in short 45 second elevator pitches. These are the presentations with posters early in the Green and Silver process videos.</p> 

<p>Here's one of 2019 Green Team's idea pitches. That's the team whose process I showed in <a href="#gd5_B01">panel B1</a>. Note that it is different than the product they ultimately designed, Areta! In total, the team pitched 6 ideas.</p>`
                    },
                    {
                        id: "gd5_C03",
                        number: "C3",
                        filename: "C03_green2019SketchModelA2.webp",
                        altText: "Green Team presenting a sketch model",
                        filename2: "C01_processWorkflow_2023.webp",
                        altText2: "2.009 process workflow",
                        videoInfo: {
                            playbackId: "DaM7yzdE44qrr01taljl00pUHPLljkEMFYDJcsu7B99014",
                            title: "2019 Green Team Sketch Model - OnBelay",
                            thumbnailTime: "75",
                            metadata: {
                                video_id: "2.009_2019_Green_SketchModelA2",
                                video_title: "2019 Green Team Sketch Model - OnBelay",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Sketch Models Concepts. ...",
                        descriptionHTML: `<p>Sketch Models Concepts.</p>
<p>After the idea pitch, teams narrow to a more focused opportunity direction.</p>

<p>For example, the Silver 2017 FireSense team started with the overall product inspiration of <span class="text-italic">"Super"</span> and narrowed to "sensing" for the sketch models. Teams split into 4 quasi-independent groups, each independently developing a different product concept within their team's direction. Again, the parallel sub-teams help to ensure rigor in breadth. They present their concepts and user needs with "soft" or "sketch" models &mdash; the sketch model review in the process videos.</p>

<p>This video is one of 2019 Green Team's sketch models. It's when their final product direction, Areta, first came into play. Altogether, the team had 4 concepts at this stage.</p>`
                    },
                    {
                        id: "gd5_C04",
                        number: "C4",
                        filename: "C04_green2019MockupModel.webp",
                        altText: "Green Team at Mockup Review",
                        filename2: "C01_processWorkflow_2023.webp",
                        altText2: "2.009 process workflow",
                        videoInfo: {
                            playbackId: "op02MnTTGuCeFZX8eBz3rspdDLmp6VhsOvHA017BDtjGk",
                            title: "2.009 2019 Green Team Mockup Review Presentation",
                            thumbnailTime: "23",
                            metadata: {
                                video_id: "2.009_2019_Green_MockupModel",
                                video_title: "2.009 2019 Green Team Mockup Review Presentation",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Sketch Model Engineering Risks. ...",
                        descriptionHTML: `<p>Sketch Model Engineering Risks.</p>
<p>In the process videos, this segment corresponds to what is called the mockup review. The process shown in the videos allowed a few weeks to prepare prototypes, or mockups, to explore key risks for each of the product concepts under consideration.</p>

<p>The video here is a demonstration of 2019 Green Team's mockup model for their final product Areta.</p>

<p>In the 2023 version of the process, I tried something different. We asked teams to explore these key risks while also developing the concept definitions and product sketch models.  So in the 2023 process there were only a few days between the Sketch Model Review and the Engineering Risks Review. The process change was to allow more time downstream for deciding the final, single team product direction and detailing a first detailed assembly design for the product. And what was the verdict regarding this process time? The extra time downstream did in fact help teams have more time when they chose their final product direction and fleshed out the concept, but it also meant teams spent less time exploring variations before making that choice. And less exploration time and quicker convergence to a single direction can, in some cases, mean the ideas a team chooses from are less mature and have unseen risks!</p>`
                    },
                    {
                        id: "gd5_C05",
                        number: "C5",
                        filename: "C05_green2019AssemblyReview.webp",
                        altText: "Green Team at Assembly Review",
                        filename2: "C01_processWorkflow_2023.webp",
                        altText2: "2.009 process workflow",
                        videoInfo: {
                            playbackId: "AAbreq9t02no02I1B01FyygzcjkoZpzr02HwAeelxCIO9Vw",
                            title: "2.009 2019 Green Team Assembly Review",
                            thumbnailTime: "2",
                            metadata: {
                                video_id: "2.009_2019_Green_AssemblyReview",
                                video_title: "2.009 2019 Green Team Assembly Review",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Assembly Review. ...",
                        descriptionHTML: `<p>Assembly Review.</p>
<p>After exploring different product concepts and risks as four separate sub-teams, each team decides on a product direction. A decision worthy of a yo-wup! (see <a href="ep2">episode 2 backstories</a>). Then the whole team needs to come together and develop a first cut at detailing a complete, physical product. That's what we discuss in the assembly review.</p>

<p>In the video, you'll see the assembly review presentation for 2019 Green Team's product Areta.</p>`
                    },
                    {
                        id: "gd5_C06",
                        number: "C6",
                        filename: "C06_green2019TechReviewDemo.webp",
                        altText: "Green Team at Tech Review Demo",
                        filename2: "C01_processWorkflow_2023.webp",
                        altText2: "2.009 process workflow",
                        videoInfo: {
                            playbackId: "sCfxKndQsaNeqFTm4t02AgL4GEons1012OUYWlzAFJJjk",
                            title: "2.009 2019 Green Team Tech Review Demo",
                            thumbnailTime: "56",
                            metadata: {
                                video_id: "2.009_2019_Green_TechReviewDemo",
                                video_title: "2.009 2019 Green Team Tech Review Demo",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Technical Review. ...",
                        descriptionHTML: `<p>Technical Review.</p>
<p>This stage involves more detailed design, prototyping, testing, and refining. The target for the technical review is the first looks-like, works-like, all-together product prototype. It's a big jump! Instructors and outside product development experts meet with the students, play with their product prototypes, and discuss issues and possible avenues for exploration. I often say that at this stage everything may not be working well, but one needs to have done enough to know where the problems are, and how to fix them.</p>

<p>This is a demonstration video that 2019 Green Team prepared for Areta at the Technical Review.</p>`
                    },
                    {
                        id: "gd5_C07",
                        number: "C7",
                        filename: "C07_green2019ProductLaunch.webp",
                        altText: "Team Marshmallows on OnBelay device",
                        filename2: "C01_processWorkflow_2023.webp",
                        altText2: "2.009 process workflow",
                        videoInfo: {
                            playbackId: "CYJa9lqeXs402htaE9Uw3nK3P6Zl3ICb01kiVQQRdlgCg",
                            title: "2.009 2019 Green Team Final Presentation",
                            thumbnailTime: "17.5",
                            metadata: {
                                video_id: "2.009_2019_Green_FP_full",
                                video_title: "2.009 2019 Green Team Final Presentation",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Final Presentation or Prototype Launch. ...",
                        descriptionHTML: `<p>Final Presentation or Prototype Launch.</p>

<p>One final major design cycle for the complete package. A looks-like works-like product prototype, a preliminary business model, and a presentation to launch and showcase your prototype to an audience of thousands, ranging from children to parents and the average consumer to design experts!</p>

<p>In panel <a href="#gd5_B01">B1</a> I included a short version of 2019 Green Team's final presentation. Now, here it is in its complete version. You'll get an explanation for how it works!</p>`
                    },
                ]
            },
        ]
    },
    "ep6": {
        preambleHTML: `<p>Play Seriously. Indeed. There's so much to say. Every class and activity aims to embody this spirit. Why? To build a culture of excitement and enthusiasm around our work. To provide examples and to set standards of execution that inspire students. To provide a hook, or mnemonic, for students to remember an idea or lesson that I hope will stick. And, to keep things fresh and challenging for me and the staff. To live what we espouse.</p>

<p>So, here's more detailed rationale for a grab bag of items for which you saw elements in the episode without explanation. A bit more what and why!</p>`,
        slideshowsList: [
            {
                slideshowId: "ep6_A",
                slideshowTitle: "Play as a mnemonic in 2.009.",
                slidesData: [
                    {
                        id: "gd6_A01",
                        number: "A1",
                        filename: "C01_2019syllabusHighlights.webp",
                        altText: "Iggy Spark Dust and Mallows",
                        videoInfo: {
                            playbackId: "ck6P3SDqHirJv7lJ7SRXkTvu49O02nVti5xR3tf4Vwh4",
                            title: "2.009 Syllabus Highlights (2019)",
                            thumbnailTime: "78.2",
                            metadata: {
                                video_id: "2.009_syllabus_highlights_2019",
                                video_title: "2.009 Syllabus Highlights (2019)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "We've featured bigger activities ...",
                        descriptionHTML: `<p class="text-italic">Play as a mnemonic in 2.009.</p>
<p>We've featured bigger activities such as the theme reveal, the build challenge, and the final prototype launch, but we try to have playful elements in every lecture. They provide punctuation, and hopefully a hook to remember key concepts that were being discussed. In the <span class="text-italic">Play Seriously</span> episode, you saw the bottle breaking on my head &mdash; part of a lecture on critique and professional ethics. You also saw Product Man bursting through glass as part of a class on systematic debugging and trouble-shooting.</p>

<p>Here's a video from our 2019 <span class="text-italic">Ignite</span> prototype launch that is a montage of different lecture topics and activities that are associated with them. In the middle, there is a short hot dog montage. A salute to what we call "dog days" in the lab. On busy weekends, we break out our hog dog machines and serve lunch for all (including traditional, halal, kosher and vegan).</p>

<p>The video was produced by <span class="text-italic">Play Seriously</span> director Brian Cassin. It's in a 4:1 aspect ratio as it was played on an 80 ft wide x 20 ft tall screen.</p>`
                    },
                    {
                        id: "gd6_A02",
                        number: "A2",
                        filename: "C02_2023balloonChallenge.webp",
                        altText: "balloon challenge towers",
                        videoInfo: {
                            playbackId: "pdhDWCAISeSJEvjGH9oTIi02OWy6m02AJLo00M01L6bWH02g",
                            title: "2.009 Balloon Challenge (2023)",
                            thumbnailTime: "58",
                            metadata: {
                                video_id: "2.009_balloon_challenge_montage_2023",
                                video_title: "2.009 Balloon Challenge (2023)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Here's just a little more detail ...",
                        descriptionHTML: `<p>Here's just a little more detail on a few other lecture activities from 2023.</p>

<p>This is a video montage of a team building exercise during the third lecture of the year and the first day students are together on their teams. After lecture material on teamwork and managing teams, students head to Killian Court for a timed challenge to build the tallest balloon tower. And the twist. Each team member has a secret behavioral trait that they need to role-play during the challenge. Some traits are helpful, and others not so much! After the activity, each team member reveals their secret role and discusses how it impacted the team's performance.</p>`
                    },
                    {
                        id: "gd6_A03",
                        number: "A3",
                        filename: "C03_2023inattentionalBlindness.webp",
                        altText: "inattentional blindness distractors",
                        videoInfo: {
                            playbackId: "cmxAo8hI9xi1fRPjZQ4S3G02S01P1SAA8PntcyWZYKlkc",
                            title: "2.009 Inattentional Blindness (2023)",
                            thumbnailTime: "64",
                            metadata: {
                                video_id: "2.009_inattentional_blindness_2023",
                                video_title: "2.009 Inattentional Blindness (2023)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "As part of our lecture on debugging ...",
                        descriptionHTML: `<p>As part of our lecture on debugging (the same one that Product Man appears in), we also do a demonstration of <a href="https://en.wikipedia.org/wiki/Inattentional_blindness" target="_blank">inattentional blindness</a>.</p>

<p>Students are working on a quiz and the Bruins and Canadiens walk through right in front of the class. Nobody notices. The message: when you are testing things for the first time, we need to be ready to see <span class="text-italic">everything</span> &mdash; including things that we don't expect or are not focused on!</p>`
                    },
                    {
                        id: "gd6_A04",
                        number: "A4",
                        filename: "C04_designForAssemblyExercise.webp",
                        altText: "design for assembly exercise",
                        videoInfo: {
                            playbackId: "hGB7esvyLGgJcJOIRFbZOybu1ORa018ISbIMr75WM3ZA",
                            title: "2.009 Design for Assembly Exercise (2023)",
                            thumbnailTime: "15.5",
                            metadata: {
                                video_id: "2.009_design_for_assembly_2023",
                                video_title: "2.009 Design for Assembly Exercise (2023)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Design for assembly in 2023. ...",
                        descriptionHTML: `<p>Design for assembly in 2023. A silly challenge that builds team spirit and generates some real-time data illustrating how different design configurations and fastener types can affect product assembly. You see the chart of the assembly time for each station that is generated as each student completes their task. So after the exercise, when we talk about design for assembly principles, students know it really matters &mdash; to them assembling their prototypes in lab and for products in mass production.</p>

<p>We often do this exercise on Parent's Weekend, and parents participate in the activity along with students in the class.</p>`
                    },
                    {
                        id: "gd6_A05",
                        number: "A5",
                        filename: "C05_2023productTeardown.webp",
                        altText: "product teardown board",
                        videoInfo: {
                            playbackId: "Dlu9oA00WZbkhf2nSE8kswnPYdpfrXG6l9vPgMzLO49M",
                            title: "2.009 Product Teardown (2023)",
                            thumbnailTime: "56",
                            metadata: {
                                video_id: "2.009_product_teardown_2023",
                                video_title: "2.009 Product Teardown (2023)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Right around when students are ...",
                        descriptionHTML: `<p>Right around when students are beginning to work on the details of their own product designs, teams complete a one-hour teardown activity in lab for an existing product somewhat related to their own design direction. At the end of the exercise, each team has worked together to generate a teardown board with all parts in the product along with identification of material type, manufacturing method, and estimated part cost.</p>

<p>In 2023, this lecture activity followed the introduction of the "chicken chart" featured in <a href="/#ep5">episode 5</a>, "Goals are Essential". Hence the chicken motif that runs through the video. All of these montage videos are produced by teaching assistants right after the lecture, and shown at the start of class about 1 week after the activity. In addition to students seeing themselves celebrated in video, the videos also serve as a reminder &mdash; a form of reflection &mdash; on the lecture topic.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep6_B",
                slideshowTitle: "Theme reveals",
                slidesData: [
                    {
                        id: "gd6_B01",
                        number: "B1",
                        filename: "A01_productMan_SuperRevealCoverImage.webp",
                        altText: "Product Man",
                        filename2: "A01b_DrDefectiveBottomImage.jpg",
                        altText2: "Dr. Defective",
                        videoInfo: {
                            playbackId: "FMMXqqALD01JgDNwI7w00Hk01g01GHyCc00PV8yti24blB9Y",
                            title: "2.009 Theme Reveal (2017)",
                            posterURL: "https://playseriously.com/back_stories/ep6/A01_productMan_SuperRevealCoverImage.jpg",
                            metadata: {
                                video_id: "2.009_2017_theme_reveal_super",
                                video_title: "2.009 Theme Reveal (2017)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "The year is 2017. The design theme is ...",
                        descriptionHTML: `<p class="text-italic">Theme reveals</p>
<p>The year is 2017. The design theme is <span class="text-italic">Super</span> &mdash; products that make us extra-ordinary.  And the year's characters are hero Product Man and Super Villian Dr. Defective. You've already seen the characters in the build challenge <a href="ep1#ep1_C2">backstories of Episode 1, panel C2</a>.</p>

<p>Here's the theme reveal lecture, where students are introduced to their inspirational product design theme, and to Product Man!</p>

<p>I started treating the theme reveal seriously as a playful event in 2010, when the design theme was <span class="text-italic">Food</span> and the reveal spoofed the challenge reveal of the (at the time) popular show <a href="https://en.wikipedia.org/wiki/Iron_Chef" target="_blank">Iron Chef</a>. Overall, the product design class 2.009 is structured as a story &mdash; a narrative where teams are part of a successful product development organization with a storied history of developing innovative new products. And as an innovation strategy, each year's teams are challenged to develop new products inspired by a guiding theme.</p>

<p>The theme reveal takes place in the last half of our second class, right when we are learning about idea generation techniques. As you see in this <span class="text-italic">Super</span> theme reveal video, I'm in the process of explaining our innovation strategy when an exciting event takes place! Typically a power outage. Then, under the cover of darkness and entertaining video elements on screen, props are quickly rolled into the classroom and setup &mdash; in about a minute. The reveal ensues and the year's character is introduced. There's a little bit of technical magic in every reveal! In this case, it's electro-chromatic film!</p>

<p>I like to think that in the reveal we get folks super excited about working hard for the term!</p>

<p>Once the theme is revealed, I also introduce an association map related to the theme and short inspirational videos and product demonstrations that represent categories in the association map. You'll hear more on this in the 2023 and 2020 reveal panels. But first, let's see a few more quick reveal videos!</p>`
                    },
                    {
                        id: "gd6_B02",
                        number: "B2",
                        filename: "A02_DavidDanger_DangerRevealCoverImage.webp",
                        altText: "David Danger",
                        videoInfo: {
                            playbackId: "700XaQCroCRbv6K7NxgFbwEj2ZTGS023zwUYsUPPa7VEQ",
                            title: "2.009 Theme Reveal (2018)",
                            posterURL: "https://playseriously.com/back_stories/ep6/A02_DavidDanger_DangerRevealCoverImage.jpg",
                            metadata: {
                                video_id: "2.009_2018_theme_reveal_danger",
                                video_title: "2.009 Theme Reveal (2018)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "2018 and the theme is Danger! ...",
                        descriptionHTML: `<p>2018 and the theme is <span class="text-italic">Danger</span>!</p>

<p>In addition to learning the year's theme, the reveal introduces the year's characters "Sir Safety" and 'David Danger". The characters are reminders of the balance between risk and playing it safe when innovating, and our responsibility for the safety of the folks for whom we design.</p>

<p>The reveal has a structure similar to what was described in <a href="#gd6_B01">panel B1</a>, but the theme, reveal content, characters, and "gag" is unique for each year. The <span class="text-italic">Danger</span> reveal was one of the few years where part of the setup (the columns hiding the team colored bricks) were positioned before the start of class, and the physical set-piece was rolled in during the reveal. The stop-motion origami animation recounting themes past, played on-screen while the set pieces rolled into the lecture hall, was created by Lauren Futami, a Teaching Assistant for the class. Thanks, Lauren! The original music was composed by Sam Watkinson.</p>`
                    },
                    {
                        id: "gd6_B03",
                        number: "B3",
                        filename: "A03_IggySparkDust_IgniteRevealCoverImage.webp",
                        altText: "Iggy Sparkdust",
                        videoInfo: {
                            playbackId: "ao1LwRFet01KcLdhVZnUjhTvVVKap012DDh83pncXh00iY",
                            title: "2.009 Theme Reveal (2019)",
                            posterURL: "https://playseriously.com/back_stories/ep6/A03_IggySparkDust_IgniteRevealCoverImage.png",
                            metadata: {
                                video_id: "2.009_2019_theme_reveal_ignite",
                                video_title: "2.009 Theme Reveal (2019)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "In 2019 the theme was Ignite! ...",
                        descriptionHTML: `<p>In 2019 the theme was <span class="text-italic">Ignite</span>!</p>

<p>Yes, it's a new term, another year's second day of class, and an annual theme reveal! It features a spinning magic tree house, and a nod to 1968's "2001: A Space Odyssey". The character for this reveal, Iggy Sparkdust, didn't make it into a <span class="text-italic">Play Seriously</span> episode, but Iggy guided you through our product development process in the <a href="ep5#ep5_B1">episode 5 backstories, panel B1</a>.</p>

<p>In this video, you can see some of the process rolling the set piece into the classroom after the "power outage" while the "themes past" video entertains the class during the transition. Teaching Assistant (TA) Erica Anderson, working with all the TAs, took the lead on the puppet theater.</p>`
                    },
                    {
                        id: "gd6_B04",
                        number: "B4",
                        filename: "A04_Wildy_WildThemeReveal.webp",
                        altText: "Wildy",
                        videoInfo: {
                            playbackId: "NfVu8KDYGpwPRWSUqrxzAUusvZcPR82BwJ3YzNz3TCY",
                            title: "2.009 Theme Reveal (2023)",
                            posterURL: "https://playseriously.com/back_stories/ep6/A04_Wildy_WildThemeReveal.jpg",
                            metadata: {
                                video_id: "2.009_2023_theme_reveal_wild",
                                video_title: "2.009 Theme Reveal (2023)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "2023. The year of Play Seriously's ...",
                        descriptionHTML: `<p>2023. The year of <span class="text-italic">Play Seriously</span>'s filming. The reveal of <span class="text-italic">Wild</span>.</p>

<p>You've seen snippets from the reveal in the episode. This video is a fun montage of the reveal class showing more of its elements. The year's theme, <span class="text-italic">Wild</span>, loosely draws inspiration from the 1963 children's book <a href="https://en.wikipedia.org/wiki/Where_the_Wild_Things_Are" target="_blank">Where the Wild Things Are</a> by Maurice Sendak. We want to design products that make us say "Wild!", and the reveal shows the transformation of the worried and fearful character "Mildy" into free thinking and confident "Wildy". The original music is by Sam Watkinson.</p>

<p>In <a href="#gd6_B01">panel B1</a> I mentioned that, after the reveal climax, I introduce an association map for the year's theme and discuss inspiring products that, in some way, also connect with the theme &mdash; to help launch student's idea generation process. The association map for the theme <span class="text-italic">Wild</span> was in the <a href="ep1#ep1_B1">episode 1 backstories, panel B1</a>.</p>`
                    },
                    {
                        id: "gd6_B05",
                        number: "B5",
                        filename: "A05_kindnessRevealCoverImage.webp",
                        altText: "Kindness Image",
                        videoInfo: {
                            playbackId: "e00lrZl01UbVOkxkHlVHfOqaz2WyebIBvJESo114eVoqE",
                            title: "2.s009 Theme Reveal (2020)",
                            posterURL: "https://playseriously.com/back_stories/ep6/A05_kindnessRevealCoverImage.png",
                            metadata: {
                                video_id: "2.s009_2020_theme_reveal_kindness",
                                video_title: "2.s009 Theme Reveal (2020)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        videoInfo2: {
                            playbackId: "KGqdwVa8vifDvfQ53yHmg6wqPFYez01wxg5Elu028oyzI",
                            title: "2.s009 Kindness in Killian",
                            thumbnailTime: "83.5",
                            metadata: {
                                video_id: "2.s009_kindness_in_killian",
                                video_title: "2.s009 Kindness in Killian",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "2020. Kindness. ...",
                        descriptionHTML: `<p>2020. <span class="text-italic">Kindness</span>.</p>

<p>The fall of the first year of the COVID pandemic. Our class is in person on a largely empty campus, operating with lots of special restrictions. To work together on our projects in lab, we wore medical grade PPE. For lectures, one of the rules was that we could have a maximum of 16 students in a classroom that normally would seat over 100. As a result, each student team was in a separate satellite classroom, and we broadcast a live stream of our in-person lectures from a main lecture room.</p>

<p>The theme reveal lecture was the first lecture in which students teams were physically together in the same room. The reveal lecture video was live-streamed to the students. This video, which is 12 minutes long, is the livestream that reveals the theme through an animation spearheaded by former teaching assistant Or Oppenheimer. The year's character is inspired by Roald Dahl's <a href="https://en.wikipedia.org/wiki/The_BFG" target="_blank">The BFG</a> &mdash; in our case the Big Friendly Guardian of Design. After the reveal, I appear as the BFG in a motion-capture digital avatar to explain the association map for the theme and show example products. So, if you are interested in seeing what follows in lecture as the outro of the reveal &mdash; the introduction of an association map and inspiration products related to the theme &mdash; you can see it here. Of course, the association map and products are different for each year's theme.</p>

<p>At the end of the live-streamed evening lecture video, the students were invited to walk along the sidewalk crossing MIT's Killian Court. Our department head at the time, Evelyn  Wang, and I were waiting outside in Killian Court to greet them with kindness. I was dressed as the year's character, the BFG. A short video showing the experience, called <span class="text-italic">Kindness in Killian</span>, is below.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep6_C",
                slideshowTitle: "New Year, new Brand",
                slidesData: [
                    {
                        id: "gd6_C01",
                        number: "C1",
                        filename: "B01_2023wildAnimatedLogoCoverImage.webp",
                        altText: "WILD logo",
                        filename2: "B01b_wildBuildChallengeLogoType.png",
                        altText2: "WILD build challenge logo type",
                        videoInfo: {
                            playbackId: "vfYLHyFAjXR6TnYMmpGwWk883XoMoRyvGqmzX2Wvu02M",
                            title: "2.009 Wild Animated Logo",
                            posterURL: "https://playseriously.com/back_stories/ep6/B01_2023wildAnimatedLogoCoverImage.png",
                            metadata: {
                                video_id: "2.009_animated_logo_wild",
                                video_title: "2.009 Wild Animated Logo",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "So, the design theme for the class is ...",
                        descriptionHTML: `<p class="text-italic">New Year, new Brand</p>
<p>So, the design theme for the class is different every year. And from episode 1 you know that the build challenge is also different every year.</p>

<p>While the structure of the class 2.009 and our product development process is similar year-to-year, gradually evolving and improving in response to a changing world environment, the elements within &mdash; the theme, the reveal, the build challenge, and the final prototoype launch &mdash; have dramatically different identities year-to-year. If you think about it, this is a bit odd as typically when building a brand identity for an organization the approach would be to develop a consistent and recognizable image.</p>

<p>Question: So why does the theming of the course change every year?<br />
Answer: We are tryng to create a unique brand and experience for each year of students, not a brand for the course! We want each year's students to know that their experience is unique for them. It is their class and that their own identity is unique and valued. Of course, for me and the staff, it's also fun to do new things. Plus, it keeps us honest. If we are asking students to create new things, shouldn't we be doing that, too?</p>

<p>Developing a unique feel for each year also involves designing a different graphic look, including logos, fonts, signage in lab and lecture, and website designs.  Here's an example of the logo type for <span class="text-italic">Wild</span>, our filming year for <span class="text-italic">Play Seriously</span>. It was used on screen at the final prototype launch. Also, below is a variation of the <span class="text-italic">Wild</span> logo type that was used for build challenge T-shirts. The logos were designed by Doctoral student Charlene "Chuck" Xia.</p>`
                    },
                    {
                        id: "gd6_C02",
                        number: "C2",
                        filename: "B02_SuperGraphicBrand.webp",
                        altText: "Super Graphic Brand",
                        blurb: "Here's an example of the graphic ...",
                        descriptionHTML: `<p>Here's an example of the graphic identity for the year <span class="text-italic">Super</span>, 2017. The year of Product Man (<a href="#gd6_B01">panel B1</a>)!</p>`
                    },
                    {
                        id: "gd6_C03",
                        number: "C3",
                        filename: "B03_dangerLogoType.webp",
                        altText: "Danger logo type",
                        blurb: "And this is the logo type for 2018. ...",
                        descriptionHTML: `<p>And this is the logo type for 2018. The year of <span class="text-italic">Danger</span>. Each year the feel of the identity is changed to match the spirit of the theme.</p>`
                    },
                    {
                        id: "gd6_C04",
                        number: "C4",
                        filename: "B04a_kindnessPoster.webp",
                        altText: "Kindness poster",
                        videoInfo2: {
                            playbackId: "HwELNCEHwok8hsIBGnHRuKAVNEPbtTFHKbfqkAN2y8o",
                            title: "2.s009 Kindness Animated Logo",
                            posterURL: "https://playseriously.com/back_stories/ep6/B04b_kindnessHeartLogoCoverImage.png",
                            metadata: {
                                video_id: "2.s009_animated_logo_kindness",
                                video_title: "2.s009 Kindness Animated Logo",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Here's just one more ...",
                        descriptionHTML: `<p>Here's just one more example. 2020, the year of <span class="text-italic">Kindness</span>. It's soft, pillowy. Comforting like a gentle cloud. The identity helps to express the year's design theme inspiration.</p>

<p>For 2020, the pandemic year, every lecture also began with our animated kindness heart logo (shown below). The keys around the heart represent each team in 2.s009 (the special edition of 2.009 during the pandemic year).</p>`
                    },
                ]
            },
            {
                slideshowId: "ep6_D",
                slideshowTitle: "About play and creativity",
                slidesData: [
                    {
                        id: "gd6_D01",
                        number: "D1",
                        filename: "D01_JohnCleesePlayAndCreativity.webp",
                        altText: "John Cleese",
                        videoInfo: {
                            playbackId: "krNSEXxXDpGUGfPmVw23P6F01XpfBZGzB4F7ybpLw7CY",
                            title: "John Cleese on Play and Creativity",
                            thumbnailTime: "44",
                            metadata: {
                                video_id: "John_Cleese_on_play_creativity",
                                video_title: "John Cleese on Play and Creativity",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "In design, a lot of the Play Seriously ...",
                        descriptionHTML: `<p class="text-italic">About play and creativity</p>

<p>In design, a lot of the <span class="text-italic">Play Seriously</span> spirit is intended to build a team culture where people feel free to be themselves, to think out loud and propose ideas, to experiment, and to push hard for excellence. I hope you can feel that spirit in the episode, the theme reveals, and the montages of activities from class in the <a href="#gd6_A01">A section</a> of this set of back stories.</p>

<p>I came across this interview with <a href="https://simple.wikipedia.org/wiki/John_Cleese" target="_blank">John Cleese</a> of Monty Python fame, amongst other things. This excerpt about play and creativity resonates with me. <span class="text-italic">Playing Seriously</span> is at its best with a team, so long as we all join the ride!</p>`
                    },
                    {
                        id: "gd6_D02",
                        number: "D2",
                        filename: "D02_HondaUnstoppableDreams.webp",
                        altText: "Honda Unstoppable Dreams",
                        blurb: "When I think about Playing Seriously, ...",
                        descriptionHTML: `<p>When I think about  <span class="text-italic">Playing Seriously</span>, it's about being invested to our fullest in the task at hand &mdash; always trying to do better and not settling with where we are &mdash; while at the same time being relentlessly positive and fulfilled in spirit. Immersed in the positivity of play!</p>

<p>In the psychology of training for sport, the mantra is to turn negative self-talk into positive thoughts and opportunities. That's how we get better. I recently came across this "Unstoppable Dreams" video (watch on <a href="https://www.youtube.com/watch?v=xnZggbD1Ses" target="_blank">YouTube</a>) from Honda Racing that I thought was pretty neat. It is about flipping negative to positive.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep6_E",
                slideshowTitle: "Princess David!",
                slidesData: [
                    {
                        id: "gd6_E01",
                        number: "E1",
                        filename: "E01_princessDavidCoverImage.webp",
                        altText: "Princess David",
                        videoInfo: {
                            playbackId: "N8fb0000V59ywSP00zxndmk28xqaqas7rK01Mzvh4VEy7LU",
                            title: "2.009 Build Challenge (2015)",
                            posterURL: "https://playseriously.com/back_stories/ep6/E01_princessDavidCoverImage.jpg",
                            metadata: {
                                video_id: "2.009_build_challenge_2015",
                                video_title: "2.009 Build Challenge (2015)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Princess David! ...",
                        descriptionHTML: `<p class="text-italic">Princess David!</p>

<p>There are so many more things that it would be fun to talk about. But, I'm going to end this episode's backstories with one more character.</p> 

<p>The year was 2015 and our design theme was <span class="text-italic">Magic</span>. The build challenge leveraged the <span class="text-italic">Princess and the Frog</span> fairy tale with a bit of an unusual twist &mdash; spellbound frogs (aka student teams) use their machines to "hop" from lily pad to lily pad across a "pond" to assemble a magic portal to the princess' potion to reveal their true selves! My son, 3 at the time, also has a cameo.  During the challenge, Princess David gets busted by campus police &mdash; for being too rambunctious! Enjoy!</p>`
                    },
                ]
            },
        ]
    },
    "ep7": {
        preambleHTML: `<p>Greetings! This is a special Halloween edition backstory! The full episode 7 backstories will post next week.</p>`,
        slideshowsList: [
            {
                slideshowId: "ep7_H",
                slideshowTitle: "Happy Halloween!",
                slidesData: [
                    {
                        id: "gd7_H01",
                        number: "H1",
                        filename: "H01_2023wildHappyHalloween.webp",
                        altText: "2.009 Halloween",
                        videoInfo: {
                            playbackId: "MBCtsWw826s9Mv4GlWrk61HsPWa0202LjiwYmdg957Ik00",
                            title: "2.009 Wild Happy Halloween (2023)",
                            thumbnailTime: "7",
                            metadata: {
                                video_id: "2.009_halloween_wild_2023",
                                video_title: "2.009 Wild Happy Halloween (2023)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "David Do-good and the Teaching Assistants ...",
                        descriptionHTML: `<p class="text-italic">Happy Halloween!</p>
<p>David Do-good (the <a href="https://en.wikipedia.org/wiki/Royal_Canadian_Mounted_Police" target="_blank">Mountie</a>) and the Teaching Assistants of 2023 (all appearing in <span class="text-italic">Play Seriously</span>) fly in to save the day. <span class="text-italic">Mildy et. al.</span> are terrorized by characters of years past &mdash; Ello, Woodsy, and the beaver from 2021, the year themed <span class="text-italic">Hello</span>. The video is by Director Brian Cassin with music composed by Sam Watkinson.</p>

<p>Each year we kick-off our 2.009 Halloween lecture with a fun video, costumes, and Canadian Halloween candy courtesy of David Do-good.</p>`
                    },
                    {
                        id: "gd7_H02",
                        number: "H2",
                        filename: "H02_Do-Re-Ween.webp",
                        altText: "Dangles in Sound of Music parody",
                        videoInfo: {
                            playbackId: "zoM01a3NgoJYCBnHaUBT01Ya9V7mFOj5X01tubN3q7Abkg",
                            title: "2.009 Do-Re-Ween (2018)",
                            posterURL: "https://playseriously.com/back_stories/ep7/H02_Do-Re-Ween.jpg",
                            metadata: {
                                video_id: "2.009_halloween_sound_of_music_2018",
                                video_title: "2.009 Do-Re-Ween (2018)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Do-Re-Ween",
                        descriptionHTML: `<p class="text-italic">Do-Re-Ween</p>

<p>Happy Halloween from David Danger and the teaching assistants of 2018 featured as the Dangles &mdash; Danger Triangles. Enjoy this <a href="https://en.wikipedia.org/wiki/The_Sound_of_Music_(film)" target="_blank">Sound of Music</a> parody! (Single screen version on <a href="https://www.youtube.com/watch?v=J-ToBoJWVlg" target="_blank">YouTube</a>.)</p>

<p>Videos like this demonstrate to the class commitment to our ethic of playing seriously. It's playful, just because, and we take doing it quite seriously. It's another role-modeling example for the student teams, and also a way for us (the staff) to bond and practice video editing skills that we will need to document in-class activities &mdash; such as those you saw in the <a href="ep6#ep6_A1">episode 6 backstories in section A</a>.</p>

<p><span class="text-italic">Do-Re-Ween</span> was directed by Teaching Assistant Or Oppenheimer and edited by the 2018 Teaching Assistants. Former Teaching Assistant Victor Hung was our videographer.</p>`
                    },
                    {
                        id: "gd7_H03",
                        number: "H3",
                        filename: "H03_Its_a_MechE_Life.webp",
                        altText: "mallows in It's a MechE Life",
                        videoInfo: {
                            playbackId: "hED2Na6QlIpUAwzS6XsH53XValAOYXz8Y8NuXPxK00GY",
                            title: "2.009 It's a MechE Life! (2019)",
                            posterURL: "https://playseriously.com/back_stories/ep7/H03_Its_a_MechE_Life.jpg",
                            metadata: {
                                video_id: "2.009_halloween_annie_2019",
                                video_title: "2.009 It's a MechE Life! (2019)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        videoInfo2: {
                            playbackId: "mV5QEXPyeGnoewXmkwjQAhb8UnFevmlViVWBce7M6wA",
                            title: "2.009 2019 Mallow Toasting",
                            thumbnailTime: "1",
                            metadata: {
                                video_id: "2.009_2019_mallow_toasting",
                                video_title: "2.009 2019 Mallow Toasting",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "It's a Meche Life!",
                        descriptionHTML: `<p class="text-italic">It's a Meche Life!</p>

<p>Halloween greetings from the year of <span class="text-italic">Ignite</span>, Iggy Sparkdust and the mallows, 2019 teaching assistants. Enjoy this parody of <a href="https://en.wikipedia.org/wiki/Annie_(1982_film)" target="_blank">Annie</a>. (Single screen version on <a href="https://www.youtube.com/watch?v=-XUjcqZFzAs&t=1s" target="_blank">YouTube</a>.)</p>

<p>Directed by former 2.009 staff member Or Oppenheimer, editied by the 2019 Teaching Assistants, and filmed by staff member Danny Goldfield.</p>

<p>This year the custom student plushies &mdash; from the build challenge &mdash; were mallows (or marsh mallows). At the final prototype launch, students gifted me a flame thrower, so below I am testing it with the class. In the spirit of Halloween a mallow gets toasted!</p>`
                    },
                    {
                        id: "gd7_H04",
                        number: "H4",
                        filename: "H04_2017_009LanderHalloween.webp",
                        altText: "Fake The Tech newspaper",
                        videoInfo: {
                            playbackId: "jQIB1Gnh5cLtXgnppDpzOvZDmW021KQhHpSNbU13Pez8",
                            title: "2.009 009lander (2017)",
                            thumbnailTime: "65",
                            metadata: {
                                video_id: "2.009_halloween_zoolander_2017",
                                video_title: "2.009 009lander (2017)",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "009Lander!",
                        descriptionHTML: `<p class="text-italic">009Lander!</p>
<p>One more time. Another Halloween and a parody of <a href="https://en.wikipedia.org/wiki/Zoolander" target="_blank">Zoolander</a> in 2017. It took a while to cleanup after this one! The torch on the door knob near the end of the video was part of a testing setup for Silver Team's fireSense, which you saw in <a href="ep5#ep5_B2">episode 5 backstories, panel B2</a>.</p>

<p><span class="text-italic">009lander</span> was scripted and directed by Teaching Assistant Georgia Van De Zande and filmed and edited by the teaching assistants of 2017 (<span class="text-italic">Super</span>).</p>`
                    },
                ]
            },
            {
                slideshowId: "ep7_P",
                slideshowTitle: "2.009 pumpkins, seriously!",
                slidesData: [
                    {
                        id: "gd7_P01",
                        number: "P1",
                        filename: "P01_2023wildPumpkin.webp",
                        altText: "Wild theme pumpkin",
                        blurb: "The theme is Wild ...",
                        descriptionHTML: `<p class="text-italic">2.009 pumpkins, seriously!</p>
<p>The theme is <span class="text-italic">Wild</span> and it's 2023, the year of filming <span class="text-italic">Play Seriously</span>. The monster pumpkin (inspired by <span class="text-italic">Where the Wild Thngs Are</span>) devours Mildy (aka me) and Ms. Chiefs representing the teams (aka the students).</p>

<p>It's a tradition to haunt the class website on Halloween and it features a theme inspired pumpkin that I carve. I usually finish the carving just before daybreak on the morning of Halloween, so it's a rush to get the photos taken outside before daylight.</p>

<p>Why the pumpkins? They're just another prompt reinforcing our culture of <span class="text-italic">Playing Seriously</span>. Being curious just because, being joyful in our work, and earnestly striving to do better. Be excited about doing!</p>`
                    },
                    {
                        id: "gd7_P02",
                        number: "P2",
                        filename: "P02_2017superPumpkin.webp",
                        altText: "Super theme pumpkin",
                        blurb: "Super unmasked.",
                        descriptionHTML: `<p>Super unmasked.</p>

<p>2017 and the theme was <span class="text-italic">Super</span>. Yes, this was the year of Product Man. The pumpkin is a symbol of the super powers and abilities that each of us have within us.</p>`
                    },
                    {
                        id: "gd7_P03",
                        number: "P3",
                        filename: "P03a_2019ignitePumpkinAnimated.gif",
                        altText: "Ignite theme pumpkin",
                        filename2: "P03b_2019ignitePumpkin.webp",
                        altText2: "Ignite theme pumpkin",
                        blurb: "2019. Ignite—our inner fire.",
                        descriptionHTML: `<p>2019. Ignite &mdash; our inner fire.</p>

<p>Free the energy and abilities within us, and let's <span class="text-italic">Play Seriously</span>!</p>

<p>Indeed, Happy Halloween from <span class="text-italic">Play Seriously</span>! If you are interested is seeing more pumpkins please checkout the <a href="https://2023.2009.mit.edu/gallery/pumpkins" target="_blank">2.009 Pumpkin Gallery</a>.</p>`
                    },
                ]
            },
        ]
    },
/*
    "ep5": {
        preambleHTML: ``,
        slideshowsList: [
            {
                slideshowId: "",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd5_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                    {
                        id: "gd5_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        videoInfo: {
                            playbackId: "",
                            title: "",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "",
                                video_title: "",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                ]
            },
            {
                slideshowId: "",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd5_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                    {
                        id: "gd5_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        videoInfo: {
                            playbackId: "",
                            title: "",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "",
                                video_title: "",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                ]
            },
        ]
    },
*/

}
