import React, { useEffect } from "react";
import MuxPlayer from "@mux/mux-player-react"; 
import "./GoDeeperCarousel.css";

const gd_base_file_directory = '/back_stories/';

function GoDeeperCard({episodeId, cardInfo}) {

  function resetMuxVideo(event) {
    event.target.load();
  }

  let videoInfo = cardInfo["videoInfo"] || '';
  let videoInfo2 = cardInfo["videoInfo2"] || '';
  let filePath2 = cardInfo["filename2"] ? gd_base_file_directory+episodeId+'/'+cardInfo.filename2 : '';


  let descriptionWithNumberHTML = "";
  if (cardInfo.descriptionHTML.startsWith('<p class="text-italic">')) {
    descriptionWithNumberHTML = cardInfo.descriptionHTML.replace('<p class="text-italic">','<p class="text-italic"><span class="gdc-card-id" style="font-style:normal;">'+cardInfo.number+'</span> ');
  } else if (cardInfo.descriptionHTML.startsWith('<p class="gd-slideshow-card-title">')) {
    descriptionWithNumberHTML = cardInfo.descriptionHTML.replace('<p class="gd-slideshow-card-title">','<p class="gd-slideshow-card-title"><span class="gdc-card-id" style="font-style:normal;">'+cardInfo.number+'</span> ');
  } else {
    descriptionWithNumberHTML = cardInfo.descriptionHTML.replace("<p>",'<p><span class="gdc-card-id">'+cardInfo.number+'</span> '); 
  }
 

  if (videoInfo === '') { // image card
    let filePath = gd_base_file_directory+episodeId+'/'+cardInfo.filename;
    return (
      <div className="gdc-card" key={cardInfo.id} id={cardInfo.id}>
        <div className="gdc-card-image-wrapper">
          <img
              className="gdc-card-image"
              src={filePath}
              alt={cardInfo.altText}
              loading="lazy"
            />
        </div>
        <div className="gdc-caption-text" dangerouslySetInnerHTML={{ __html: descriptionWithNumberHTML}}></div>
        {videoInfo2 &&
        <MuxPlayer
          streamType="on-demand"
          playbackId={videoInfo2.playbackId}
          thumbnailTime={videoInfo2.thumbnailTime}
          title={videoInfo2.title}
          metadata={videoInfo2.metadata}
          defaultHiddenCaptions="true"
          primaryColor="#FFFFFF"
          accent-color="#000000"
          onEnded={resetMuxVideo}
        />
        }
        {filePath2 && 
        <div className="gdc-card-image-wrapper">
        <img
            className="gdc-card-image"
            src={filePath2}
            alt={cardInfo.altText2}
            loading="lazy"
          />
        </div>
        }
      </div>
    );
  } else { // video card
    if (videoInfo.posterURL) {
      return (
        <div className="gdc-card" key={cardInfo.id} id={cardInfo.id}>
            <MuxPlayer
                streamType="on-demand"
                playbackId={videoInfo.playbackId}
                poster={videoInfo.posterURL}
                title={videoInfo.title}
                metadata={videoInfo.metadata}
                defaultHiddenCaptions="true"
                primaryColor="#FFFFFF"
                accent-color="#000000"
                onEnded={resetMuxVideo}
                />
          <div className="gdc-caption-text" dangerouslySetInnerHTML={{ __html: descriptionWithNumberHTML}}></div>
          {videoInfo2 &&
          <MuxPlayer
            streamType="on-demand"
            playbackId={videoInfo2.playbackId}
            thumbnailTime={videoInfo2.thumbnailTime}
            title={videoInfo2.title}
            metadata={videoInfo2.metadata}
            defaultHiddenCaptions="true"
            primaryColor="#FFFFFF"
            accent-color="#000000"
            onEnded={resetMuxVideo}
          />
          }
          {filePath2 && 
          <div className="gdc-card-image-wrapper">
          <img
              className="gdc-card-image"
              src={filePath2}
              alt={cardInfo.altText2}
              loading="lazy"
            />
          </div>
          }
        </div>
      );
    } else {
      return (
        <div className="gdc-card" key={cardInfo.id} id={cardInfo.id}>
            <MuxPlayer
                streamType="on-demand"
                playbackId={videoInfo.playbackId}
                thumbnailTime={videoInfo.thumbnailTime}
                title={videoInfo.title}
                metadata={videoInfo.metadata}
                defaultHiddenCaptions="true"
                primaryColor="#FFFFFF"
                accent-color="#000000"
                onEnded={resetMuxVideo}
                />
          <div className="gdc-caption-text" dangerouslySetInnerHTML={{ __html: descriptionWithNumberHTML}}></div>
          {videoInfo2 &&
          <MuxPlayer
            streamType="on-demand"
            playbackId={videoInfo2.playbackId}
            thumbnailTime={videoInfo2.thumbnailTime}
            title={videoInfo2.title}
            metadata={videoInfo2.metadata}
            defaultHiddenCaptions="true"
            primaryColor="#FFFFFF"
            accent-color="#000000"
            onEnded={resetMuxVideo}
          />
          }
          {filePath2 && 
          <div className="gdc-card-image-wrapper">
          <img
              className="gdc-card-image"
              src={filePath2}
              alt={cardInfo.altText2}
              loading="lazy"
            />
          </div>
          }
        </div>
      );
    }
  }
}

function GoDeeperCarousel({episodeId, slideshowsList, requestedCardId}) {
  const cardsLists = slideshowsList.map(slideshowData => slideshowData.slidesData.map(slideInfo => <GoDeeperCard episodeId={episodeId} cardInfo={slideInfo} /> ) );
  const cards = cardsLists.flat();
  const carouselId = "carousel-"+episodeId;
  let currentCardIndex = 0;

  useEffect(() => {
    setTimeout(() => { /* Timeout needed in order to work on Safari browsers */
      showRequestedCarouselCard();
    }, 30);
  });
  
  function hideCarousel() {
    document.getElementById(carouselId).close();
  };

  function showRequestedCarouselCard() {
    let carouselCards = document.getElementsByClassName("gdc-card");
    let requestedCard = carouselCards[0]; // first card by default
    let requestedCardIndex = 0;
    for (let cardIndex=0; cardIndex<carouselCards.length; cardIndex++) { // find the specified card
      let card = carouselCards[cardIndex];
      if (card.id === requestedCardId) {
        requestedCard = card;
        requestedCardIndex = cardIndex;
        break;
      }
    }

    let carouselScroller = document.getElementsByClassName("gdc-scrolling-wrapper")[0];
    carouselScroller.scrollLeft = requestedCard.offsetLeft;
    currentCardIndex = requestedCardIndex;
  }

  function showPreviousCarouselCard() {
    let carouselCards = document.getElementsByClassName("gdc-card");
    let carouselScroller = document.getElementsByClassName("gdc-scrolling-wrapper")[0];
    if (currentCardIndex === 0) { // at first card, exit carousel
      hideCarousel();
    } else {
      let prevCardIndex = currentCardIndex-1;
      let previousCard = carouselCards[prevCardIndex];
      carouselScroller.scrollLeft = previousCard.offsetLeft;
      currentCardIndex = prevCardIndex;
    }
  }

  function showNextCarouselCard() {
    let carouselCards = document.getElementsByClassName("gdc-card");
    let carouselScroller = document.getElementsByClassName("gdc-scrolling-wrapper")[0];
    if (currentCardIndex === (carouselCards.length-1)) { // at last card, exit carousel
      hideCarousel();
    } else {
      let nextCardIndex = currentCardIndex+1;
      let nextCard = carouselCards[nextCardIndex];
      carouselScroller.scrollLeft = nextCard.offsetLeft;
      currentCardIndex = nextCardIndex;
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'ArrowLeft') {
      showPreviousCarouselCard();
    } else if (event.key === 'ArrowRight') {
      showNextCarouselCard();
    } else if (event.key === 'Escape') {
      hideCarousel();
    }
  };

  return (
    <dialog className="gdc-popup" id={carouselId} onKeyDown={handleKeyDown} tabIndex="0">
      <span className="gdc-close" onClick={hideCarousel}>&times;</span>
      <div className="gdc-arrow-button gdc-left-button" onClick={showPreviousCarouselCard}><span className="gdc-left-arrow"></span></div>
      <div className="gdc-arrow-button gdc-right-button" onClick={showNextCarouselCard}><span className="gdc-right-arrow"></span></div>
      <div className="gdc-scrolling-wrapper">
          { cards }
        </div>
    </dialog>
  );
}

export default GoDeeperCarousel;