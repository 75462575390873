export const chaptersInfo = [{
    chapterNumber: 0,
    divID: 'ep0',
    name: 'Introduction',
    backStories: true,
    releaseDate: 'September XXth',
    descriptionHTML: `<p>Hello! I'm David Wallace. The goal of my teaching career has been to create learning environments that inspire the confidence to dream big &mdash; even audaciously. Please join me in this first episode as I introduce the ideas I'll share in <span class="text-italic">Play Seriously</span>. I hope the series will help you imagine, take action, and feel great satisfaction on whatever road your passions may take you.</p>`,
    videoLink: "https://www.youtube.com/embed/vWLmR3R575M?si=slZKkqfJtUFXrvhr",
  }, {
    chapterNumber: 1,
    divID: 'ep1',
    name: 'Everything is an Example',
    backStories: true,
    trailerDate: 'September 17',
    releaseDate: 'September 19',
    trailerHTML: `<p>It's <span class="text-italic">Play Seriously</span> Episode 1—the trailer. Here's a sneak peak of how I use the 2.009 build challenge to bring to life the principle <span class="text-italic">Everything is an example</span>.</p>`,
    descriptionHTML: `<p>Hello again! Episode 1 is here! We are what we practice, and we teach what we are. I believe everything that I do is an example. In this episode, a 2.009 activity called the build challenge is used to bring this principle to life. It's extreme, kind of crazy, and euphoric. What are we building? We're building an audacious willingness to love what we do. To be all-in for the extraordinary. Yes, it's fun! And we do it because Everything is an example.</p>`,
    videoLink: 'ZBZ9DnsmcSc',
    trailerInfo: {
      playbackId: "yFIVL92no33iMG1lUP3pA02hg02HC2l4L1taSLM3xhfTw",
      title: "Episode 1 Trailer",
      metadata: {
          video_id: "ep1_trailer",
          video_title: "Episode 1 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#C941F2",
      posterURL: "https://playseriously.com/video_covers/Ep01_Mux_Thumb.jpg",
    },
  }, {
    chapterNumber: 2,
    divID: 'ep2',
    name: 'Have a Plan and Be Ready to Change It',
    backStories: true,
    trailerDate: 'September 24',
    releaseDate: 'September 26',
    trailerHTML: `<p>Yes, indeed! Have a plan and be ready to change it! What's that all about? The next episode is coming on Thursday! And until then, please watch the trailer to learn more!</p>`,
    descriptionHTML: `<p>Hello, Hello! In designing their product, every team member will invest &mdash; really believe &mdash; in ideas that end up on the cutting board. How do we make such hard decisions, choosing between options with truthfulness and kindness? How do we achieve our best possible outcome? Episode 2: Have a plan and be ready to change it!</p>`,
    videoLink: 'L4ONf9YmBXQ',
    trailerInfo: {
      playbackId: "mwFS71xWzNHsjYcNlqxG5uh8anO8nm3g3dmjNq01k1yo",
      title: "Episode 2 Trailer",
      metadata: {
          video_id: "ep2_trailer",
          video_title: "Episode 2 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#C941F2",
      posterURL: "https://playseriously.com/video_covers/Ep02_Mux_Thumb.jpg",
    },
  }, {
    chapterNumber: 3,
    divID: 'ep3',
    name: "Make Students' Stars Shine",
    backStories: true,
    trailerDate: 'October 1',
    releaseDate: 'October 3',
    trailerHTML: `<p>Episode 3, the trailer &mdash; <span class="text-italic">Make Students' Stars Shine</span>. It's all about creating environments where we can dream big, take action, and prosper in learning! Please watch the trailer to learn more and follow on Instagram <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a>!
</p>`,
    descriptionHTML: `<p>More than anything, my goal is to inspire students to dream big and take action towards their goals. To be good and to feel good about it.  Indeed, as a professor &mdash; a teacher &mdash; my goal is to make students' stars shine. How can we build environments where learners prosper? Please watch episode 3 and follow <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a> on Instagram for additional content!</p>`,
    videoLink: '2Qqgx8D7unk',
    trailerInfo: {
      playbackId: "PChVphcnQS7mZxUgSFv004Gd6g7802h1ZH3WpblYvxvs8",
      title: "Episode 3 Trailer",
      metadata: {
          video_id: "ep3_trailer",
          video_title: "Episode 3 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#2da264",
      posterURL: "https://playseriously.com/video_covers/Ep03_Mux_Thumb.jpg",
    },
  }, {
    chapterNumber: 4,
    divID: 'ep4',
    name: 'Everyone has Something to Teach',
    backStories: true,
    trailerDate: 'October 8',
    releaseDate: 'October 10',
    trailerHTML: `<p>The trailer for episode 4 has arrived! Finnegan (?!) and I open doors to less obvious teachers &mdash; and keeping "wow, that was interesting!" in our lives. The full episode arrives noon Thursday! Until then, please watch the trailer and follow on Instagram <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a>!</p>`,
    descriptionHTML: `<p>Over the course of my career, almost daily I've come away from something unexpectedly changed, improved. Thinking "wow, that was interesting!" In fact, we're always surrounded by opportunities to learn! We just need to have the humility, curiosity and respect to engage with the world around us. In episode 4, Finnegan (who's that?!) and I open the door to less obvious teachers. The idea that <span class="text-italic">everyone</span> (and everything) <span class="text-italic">has something to teach!</span></p>
    
    <p>Please watch the episode and follow <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a> on Instagram for additional content. Thanks!</p>`,
    videoLink: 'zCojR1FQPyE',
    trailerInfo: {
      playbackId: "YFjr4m7jPTiQXpluYK02q3RskMPQlC901XnLDmyZ00e9hY",
      title: "Episode 4 Trailer",
      metadata: {
          video_id: "ep4_trailer",
          video_title: "Episode 4 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#F24141",
      posterURL: "https://playseriously.com/video_covers/Ep04_Mux_Thumb.jpg",
    },
  }, 
  {
    chapterNumber: 5,
    divID: 'ep5',
    name: 'Goals are Essential',
    backStories: true,
    trailerDate: 'October 15',
    releaseDate: 'October 17',
    trailerHTML: `<p>I believe that it's in our nature to imagine our future &mdash; what we want to be. But how will we get there? The steps to our dreams begin in the here and now. <span class="text-italic">Goals are Essential</span>. It's time to act! The full epsiode arrives on Thursday, and please follow on Instagram <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a>. Thanks!</p>`,
    descriptionHTML: `<p>I believe that it's our nature to imagine our future &mdash; what we want to be. But how to get there? Dreaming and hoping is not enough. The steps to our dreams begin in the here and now! Episode 5 is <span class="text-italic">Goals are Essential</span>, and it's time to act! It's time to get started on your roadmap to the future.</p>

<p>Please watch the episode and follow  <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a> on Instagram for additional content. Thanks!</p>`,
    videoLink: 'seqCXGjtAIg',
    trailerInfo: {
      playbackId: "fC701udGopDKyuN3V9yj3KUry01e2aB2Uojf3rNCxWxTI",
      title: "Episode 5 Trailer",
      metadata: {
          video_id: "ep5_trailer",
          video_title: "Episode 5 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#E4E4E4",
      posterURL: "https://playseriously.com/video_covers/Ep05_Mux_Thumb.jpg",
    },
  },  
  {
    chapterNumber: 6,
    divID: 'ep6',
    name: 'Play Seriously',
    backStories: true,
    trailerDate: 'October 22',
    releaseDate: 'October 24',
    trailerHTML: `<p>Our trailer for episode 6 has arrived. <span class="text-italic">Play Seriously!</span> Yup, this episode is the series namesake. We're going to play and learn something serious, too.</p>

<p>The full episode arrives noon Thursday and, as usual, there are extra goodies on instagram <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a>.</p>`,
    descriptionHTML: `<p><span class="text-italic">Play Seriously!</span> Yes indeed, episode 6 is <span class="text-italic">Play Seriously</span> &mdash; the series namesake! Natually, the episode is plenty playful AND it's serious. I explain why I believe in playing seriously.  As usual, there are extra goodies <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a> on Instagram. Please follow us!</p> 

<p>Play se-ri-ous-ly: /plā/ /ˈsirēəslē/<br />
To engage in a serious activity with earnest intent, not lightly or superficially, with a playful spirit of freedom and enjoyment.</p>`,
    videoLink: 'hez1pymGUNU',
    trailerInfo: {
      playbackId: "nk2B01BWaW14xFaOAO023npjUJbDuYBTeLeL9eo028gjn8",
      title: "Episode 6 Trailer",
      metadata: {
          video_id: "ep6_trailer",
          video_title: "Episode 6 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#FFFA81",
      posterURL: "https://playseriously.com/video_covers/Ep06_Mux_Thumb.jpg",
    },
  }, 
  {
    chapterNumber: 7,
    divID: 'ep7',
    name: 'The Euphoria of Growth',
    backStories: true,
    trailerDate: 'October 29',
    releaseDate: 'October 31',
    trailerHTML: `<p>Please checkout the trailer for our final launch event. It's all about feeling positive, experiencing reward, and thoughts about where reward comes from.  Experience the <span class="text-italic">Euphoria of Growth</span>!</p>

<p>The full episode arrives on Thursday! And, as usual, there are extra goodies on Instagram <a href="https://instagram.com/mitplayseriously" target="_blank">@mitplayseriously</a>.</p>`,
    descriptionHTML: `<p>It's our final launch event! It's euphoric. But is the euphoria in hearing the accolades of thousands? In the show? Or is the euphoria from knowing what it took for you to get there?</p>

<p>Feel of the <span class="text-italic">Euphoria of Growth</span>. Feel that you know you've played seriously!</p>`,
    videoLink: '5Xx_X3h29Gk',
    trailerInfo: {
      playbackId: "8dk02J01Yz4hj4b9d2LVnbtRsuCFe01UshS6lTLeo3tosM",
      title: "Episode 7 Trailer",
      metadata: {
          video_id: "ep7_trailer",
          video_title: "Episode 7 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#47B1F3",
      posterURL: "https://playseriously.com/video_covers/Ep07_Mux_Thumb.jpg",
    },
  }];
