import React, { useEffect, useState  } from "react";
import ShareMenu from "./ShareMenu";
import HomeMenu from "./HomeMenu";
import { Link, useLocation} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import MecheLogo from '../Assets/mechelogo.png';
// import MecheLogo from '../Assets/mechelogo.svg';
import {IoLogoFacebook, IoLogoInstagram} from "react-icons/io5";
import "./Navbar.css";

function ScrollToTop() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return null;
}

const Navbar = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  let headerBackgroundClass = (pathname=='/' || pathname=='/Ep') ? 'header' : 'header blue-background';

  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollbnt = {
    hover: { scale: 1.3},
    tap: { scale: 0.9},
    show: {
      transition: { type: "spring", stiffness: 400, damping: 10 }
    }
  }

  const [color, setColor] = useState('var(--white)'); // Default color
  const [activeColor, setActiveColor] = useState('var(--yellow)')

  useEffect(() => {
    const handleHashChange = () => {
      // Check if the hash matches the specified chapter
      if (window.location.hash === `#ep6`) {
        setColor('var(--blue)'); // Change color to blue if it matches
      } else {
        setColor('var(--white)'); // Reset to default color
      }
    };
    // Initial check
    handleHashChange();
    // Add event listener for hash change
    window.addEventListener('hashchange', handleHashChange);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []); 

  if (isSmallScreen) {
  return(
    <header className={ headerBackgroundClass }>
      <nav className="navbar">
        <ul style={{ color }}>
            <li>
            <HomeMenu/>
            </li>
            <li className="navbar-center">
              <a href="https://meche.mit.edu/" target="_blank" rel="noopener noreferrer">
              <img src={MecheLogo} 
              style={{ width: '130%', 
              height: 'auto', 
              maxHeight: 80, 
              }} alt="MIT Meche" />
              </a>
            </li>
            <li className="navbar-right">
            <ShareMenu />
            </li>
            <a href="https://www.facebook.com/playseriously" style={{ textDecoration: 'none', color: 'inherit' }}>
              <li><IoLogoFacebook size={32}/></li></a>
              
            <a href="https://www.instagram.com/mitplayseriously/" style={{ textDecoration: 'none', color: 'inherit' }}><li>
              <IoLogoInstagram size={32}/></li></a>

            {/*<a href="https://www.tiktok.com/@playseriously" style={{ textDecoration: 'none', color: 'inherit' }}><li>
              <IoLogoTiktok size={32}/></li></a>*/}
        </ul>
      </nav>
  </header>
  )}
  else{
    return (
      <header className={ headerBackgroundClass }>
      <nav className="navbar">
        <ul style={{ color }}>
            <li>
            <a href="https://meche.mit.edu/" target="_blank" rel="noopener noreferrer">
              <img className="navbar-item" src={MecheLogo} style={{ height: 50 }} alt="MIT Meche" />
            </a>
            </li>
            <li>
            <ScrollToTop />
              <Link to="/#home" className="navbar-item"> 
                <div> Home</div>
              </Link>
            </li>
            <li>
            <ScrollToTop />
              <Link to="/BackStories" className="navbar-item"> 
                <div> Back Stories</div>
              </Link>
            </li>
            <li>
            <ScrollToTop />
              <Link to="https://forum.playseriously.com" target="_blank" className="navbar-item"> 
                <div> Forum</div>
              </Link>
            </li>
            <li>
              <Link to="/About" className="navbar-item"> 
                About
              </Link>
            </li>
            <li>
            <ShareMenu /> 
            </li>
            <a href="https://www.facebook.com/playseriously" style={{ textDecoration: 'none', color: 'inherit' }}>
              <li style={{ color }}><IoLogoFacebook size={24}/></li></a>
              
            <a href="https://www.instagram.com/mitplayseriously/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <li style={{ color }}><IoLogoInstagram size={24}/></li></a>

            {/*<a href="https://www.tiktok.com/@playseriously" style={{ textDecoration: 'none', color: 'inherit' }}><li>
              <IoLogoTiktok size={24}/></li></a>*/}
        </ul>
      </nav>
  </header>
    )}
};

export default Navbar;