import './Scrollbar.css'
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useScroll, motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { globalLocation } from '../../Pages/Home';

export function setActive(location) {
  // console.log(`${location}-btn`);
  const divElement = document.getElementById(`${location}-btn`);
  if (divElement) {
      // Remove the active class from all div elements
      const divElements = document.querySelectorAll('.active');
      divElements.forEach((element) => element.classList.remove('active'));
      // Add the active class to the current div element
      divElement.classList.add('active');
  } else {
    console.error("Couldn't find the div element!");
  }
}

// function ScrollToTop() {
//     const { hash } = useLocation();
  
//     useEffect(() => {
//       if (hash) {
//         const id = hash.replace('#', '');
//         const element = document.getElementById(id);
//         if (element) {
//           element.scrollIntoView({ behavior: 'smooth' });
//         }
//       }
//     }, [hash]);
  
//     return null;
// }

const scrollbnt = {
  hover: {scale: 1.5},
	tap: {scale: 0.9},
  show: {
    transition: { type: "spring", stiffness: 400, damping: 10 }
  }
}

const Scrollbar = () => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const location = useLocation();

    // console.log(`scroll ${globalLocation}`)

    const scrollableContainer = React.createRef();
    const { y } = useScroll(scrollableContainer);

    if (location.pathname === '/') {
      return(
        <div class="nav-panel">
            <nav>
                {/* <ScrollToTop />     */}
                <ul>

                <li>
                    <a href="#home">
                    <motion.div className="nav-btn square"
                      id="home-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#introduction">
                    <motion.div class="nav-btn circle intro-color"
                      id="introduction-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep1">
                    <motion.div class="nav-btn circle ep1-color"
                      id="ep1-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep2">
                    <motion.div class="nav-btn circle ep2-color"
                      id="ep2-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep3">
                    <motion.div class="nav-btn circle ep3-color"
                      id="ep3-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep4">
                    <motion.div class="nav-btn circle ep4-color"
                      id="ep4-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep5">
                    <motion.div class="nav-btn circle ep5-color"
                      id="ep5-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep6">
                    <motion.div class="nav-btn circle ep6-color"
                      id="ep6-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>


                <li>
                <a href="#ep7">
                    <motion.div class="nav-btn circle ep7-color"
                      id="ep7-btn"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show">
                      </motion.div>
                    </a>
                </li>
                </ul>
            </nav>
        </div>
      );
    } else{
      return null;
    }
}

export default Scrollbar;
